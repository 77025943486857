import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import CatIcon from './images/cat-icon.svg';
import DogIcon from './images/dog-icon.svg';
import ReptileIcon from './images/turtle-icon.svg';
import HorseIcon from './images/horse-icon.svg';
import BirdIcon from './images/bird-icon.svg';
import FishIcon from './images/fish-icon.svg';
import PoultryIcon from './images/duck-icon.svg';
import LivestockIcon from './images/livestock-icon.svg';
// import SmallPetIcon from './images/small-pet-icon.svg';
import NoImage from './images/noImageIcon.svg'

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import moment from 'moment';
import { getIsHouseOwnerListing } from '../../util/listingHelpers';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const getPetIcon = (petKey) => {
  const iconMap = {
    catsList: CatIcon,
    dogsList: DogIcon,
    horsesList: HorseIcon,
    birdsList: BirdIcon,
    fishesList: FishIcon,
    livestockList: LivestockIcon,
    poultryList: PoultryIcon,
    reptilesList: ReptileIcon,
    smallPetsList: NoImage,
    // smallPetsList: SmallPetIcon,
  };

  return iconMap[petKey] || null;
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ListingSimpleCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    disableLink,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData } = currentListing.attributes;
  const { availabilityInterval, pets = '', location, headline } = publicData;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const isHomeOwnerListing = getIsHouseOwnerListing(currentListing);

  return (
    <div className={classes}  >

      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>

      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
      </div>

    </div>
  );
};

ListingSimpleCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingSimpleCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingSimpleCardComponent);
