import React, { useState } from 'react';

import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from 'react-intl';
import { composeValidators, nonEmptyArray, required } from '../../../../../util/validators';
import { ARRAY_ERROR } from 'final-form';
import { useForm } from 'react-final-form';

import FieldAzureImageUpload from '../../../../../components/FieldAzureImageUpload/FieldAzureImageUpload';
import { Button, FieldSingleSelect, FieldTextInput } from '../../../../../components';
import css from './FieldArray.module.css'

const ReptileFieldArray = props => {
    const {
        intl,
        push,
        remove,
    } = props;


    const form = useForm();
    const { errors, values } = form.getState();

    const initialCompletedForms = values?.reptilesList?.reduce((acc, item, index) => {
        acc[index] = item?.isCompleted || false;
        return acc;
    }, {});

    const [completedForms, setCompletedForms] = useState(initialCompletedForms);

    const handleSave = (index, name) => {
        setCompletedForms(prev => ({
            ...prev,
            [index]: true,
        }));

        const fieldName = `${name}isCompleted`;
        form.change(fieldName, true);
    };

    const handleEdit = index => {
        setCompletedForms(prev => ({
            ...prev,
            [index]: false,
        }));
    };

    const regularMedicationLabel = intl.formatMessage({ id: 'ReptileFieldArray.regularMedicationLabel' });
    const regularMedicationOptions = [
        { option: "yes", label: 'Yes' },
        { option: "no", label: 'No' },
    ];
    return (
        <FieldArray name="reptilesList">
            {({ fields }) => fields.map((name, index) => {

                const fieldErrors = errors?.reptilesList && errors.reptilesList[index];
                const imagesError = fieldErrors && fieldErrors.images && fieldErrors.images[ARRAY_ERROR];
                const isCompleted = completedForms[index];
                const petName = values?.reptilesList[index].name;

                const classes = classNames(css.root, {
                    [css.isCompleted]: isCompleted
                });

                return (
                    isCompleted ? (
                        <div className={classes} key={index}>
                            <p>
                                <FormattedMessage
                                    id="ReptileFieldArray.info"
                                    values={{ name: petName }}
                                />
                            </p>
                            <Button
                                className={css.editButton}
                                type="button"
                                onClick={() => handleEdit(index)}
                            >
                                <FormattedMessage id="ReptileFieldArray.editButton" />
                            </Button>
                        </div>
                    ) : (
                        <div className={classes} key={index}>
                            <p className={css.title}>
                                <FormattedMessage id="ReptileFieldArray.title" />
                            </p>
                            <div className={css.container}>
                                <div className={css.nameContainer}>
                                    <FieldTextInput
                                        id={`${name}name`}
                                        name={`${name}name`}
                                        className={css.name}
                                        type="text"
                                        label={intl.formatMessage({ id: 'ReptileFieldArray.nameLabel' })}
                                    // validate={required(intl.formatMessage({ id: 'ReptileFieldArray.petNameRequired' }))}
                                    />
                                    <FieldTextInput
                                        id={`${name}age`}
                                        name={`${name}age`}
                                        className={css.age}
                                        type="number"
                                        label={intl.formatMessage({ id: 'ReptileFieldArray.ageLabel' })}
                                    // validate={required(intl.formatMessage({ id: 'ReptileFieldArray.petAgeRequired' }))}

                                    />
                                </div>
                                <FieldTextInput
                                    id={`${name}breed`}
                                    name={`${name}breed`}
                                    className={css.breed}
                                    type="text"
                                    label={intl.formatMessage({ id: 'ReptileFieldArray.breedLabel' })}
                                    validate={required(intl.formatMessage({ id: 'ReptileFieldArray.breedRequired' }))}
                                />
                                <FieldSingleSelect
                                    id={`${name}regularMedication`}
                                    name={`${name}regularMedication`}
                                    className={css.fieldClass}
                                    label={regularMedicationLabel}
                                    options={regularMedicationOptions}
                                    validate={required(intl.formatMessage({ id: 'ReptileFieldArray.fieldRequired' }))}
                                />

                                <div className={css.imageContainer}>
                                    <FieldArray
                                        name={`${name}images`}
                                    // validate={composeValidators(
                                    //     nonEmptyArray(
                                    //         intl.formatMessage({
                                    //             id: 'EditListingPhotosForm.imageRequired',
                                    //         })
                                    //     )
                                    // )}
                                    >
                                        {({ fields }) => fields.map((imageName, imageIndex) => {
                                            return (
                                                <div key={imageIndex}>
                                                    <FieldAzureImageUpload
                                                        label={intl.formatMessage({ id: "ReptileFieldArray.addPhoto" })}
                                                        id={imageName}
                                                        name={imageName}
                                                        onUploadComplete={() => { push(`${name}images`, '') }}
                                                        onImageRemove={() => { remove(`${name}images`, imageIndex) }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </FieldArray>
                                </div>
                                {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}
                                <div className={css.buttonContainer}>
                                    <Button
                                        className={css.saveButton}
                                        type="button"
                                        disabled={!!fieldErrors}
                                        onClick={() => handleSave(index, name)}
                                    >
                                        <FormattedMessage id="ReptileFieldArray.saveButton" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                )
            })}
        </FieldArray >
    );
};

export default ReptileFieldArray;