import React, { useEffect, useState } from 'react';
import { bool, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../../../util/reactIntl';
import { Form, PrimaryButton, Button, ListingLink } from '../../../../../components';

import css from './EditListingAvailabilityPlanCalendarForm.module.css';
import './calendar.css';
import moment from 'moment';
import FieldTimeZoneSelect from '../FieldTimeZoneSelect/FieldTimeZoneSelect';

const EditListingAvailabilityPlanCalendarFormComponent = props => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const [availability, setAvailability] = useState([]);
    const monthsToDisplay = 12;

    const [savedValues, setSavedValues] = useState();

    return (
        <FinalForm
            {...props}
            mutators={{
                ...arrayMutators,
            }}
            render={fieldRenderProps => {
                const {
                    rootClassName,
                    className,
                    formId,
                    form: formApi,
                    handleSubmit,
                    inProgress,
                    updateInProgress,
                    fetchErrors,
                    values,
                    disabled,
                    ready,
                    updated,
                    invalid,
                    pristine,
                    allExceptions,
                    availabilityInterval,
                    saveActionMsg,
                    listing,
                } = fieldRenderProps;

                const [availabilityStart, setAvailabilityStart] = useState(moment(values.start).tz(values.timezone));
                const [availabilityEnd, setAvailabilityEnd] = useState(moment(values.end).tz(values.timezone));

                const handleSave = (values) => {

                    const currentMonthStart = moment().startOf('day');
                    const endOfInterval = currentMonthStart.clone().add(12, 'months').subtract(1, 'day').startOf('day');

                    let exceptionsBeforeInterval = null;
                    let exceptionsAfterInterval = null;

                    if (availabilityStart && availabilityStart.isAfter(currentMonthStart, 'day')) {
                        exceptionsBeforeInterval = {
                            start: currentMonthStart,
                            end: availabilityStart.clone().startOf('day')
                        };
                    }

                    if (availabilityEnd && availabilityEnd.isBefore(endOfInterval, 'day')) {
                        exceptionsAfterInterval = {
                            start: availabilityEnd.clone().add(1, 'day').startOf('day'),
                            end: endOfInterval
                        };
                    }


                    formApi.change('availabilityInterval', { start: availabilityStart.toISOString(), end: availabilityEnd.toISOString() });

                    formApi.change('exceptionsBeforeInterval', { start: exceptionsBeforeInterval.start.toDate(), end: exceptionsBeforeInterval.end.toDate() });
                    formApi.change('exceptionsAfterInterval', { start: exceptionsAfterInterval.start.toDate(), end: exceptionsAfterInterval.end.toDate() });

                    setSavedValues(true);
                };

                const classes = classNames(rootClassName || css.root, className);
                const { updateListingError } = fetchErrors || {};
                const scheduleSubmitInProgress = inProgress;
                const scheduleSubmitDisabled = scheduleSubmitInProgress || !values.timezone;

                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                const submitDisabled = invalid || disabled || submitInProgress || !availabilityStart || !availabilityEnd || !savedValues;


                useEffect(() => {
                    const startMonth = currentMonth;
                    const newAvailability = [];

                    for (let i = 0; i < monthsToDisplay; i++) {

                        const monthDate = moment([currentYear, currentMonth, 1]).tz(values.timezone).add(i, 'months');;
                        const daysInMonth = monthDate.daysInMonth();

                        for (let day = 1; day <= daysInMonth; day++) {
                            const date = monthDate.clone().date(day).startOf('day');
                            const dateStr = date.format('YYYY-MM-DD');
                            let isAvailable = false;

                            if (availabilityStart && !availabilityEnd) {
                                if (date.isSame(availabilityStart)) {
                                    isAvailable = true;
                                }
                            } else if (availabilityStart && availabilityEnd) {
                                if (date.isSameOrAfter(availabilityStart) && date.isSameOrBefore(availabilityEnd)) {
                                    isAvailable = true;
                                }
                            }

                            newAvailability.push({ date: dateStr, isAvailable: isAvailable });
                        }
                    }

                    setAvailability(newAvailability);
                }, [availabilityStart, availabilityEnd]);


                const toggleDateAvailability = (dateStr) => {

                    const pickedDate = moment(dateStr).tz(values.timezone).startOf('day');

                    if (!availabilityStart) {
                        setAvailabilityStart(pickedDate);
                    } else if (!availabilityEnd && pickedDate.isAfter(availabilityStart)) {
                        setAvailabilityEnd(pickedDate);
                    } else {
                        setAvailabilityStart(pickedDate);
                        setAvailabilityEnd(null);
                    }

                };


                const renderCalendarGrid = (currentYear, monthsToDisplay, availability, toggleDateAvailability) => {
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth(); // 0-based index

                    let startMonth = currentMonth;

                    const months = [];

                    for (let i = 0; i < monthsToDisplay; i++) {
                        const monthIndex = startMonth + i;
                        const displayMonth = monthIndex % 12;
                        const displayYear = currentYear + Math.floor(monthIndex / 12);

                        const monthDate = new Date(displayYear, displayMonth, 1);

                        months.push(
                            <Calendar
                                key={`${displayYear}-${displayMonth}`}
                                month={monthDate}
                                availability={availability.filter(item => {
                                    const date = new Date(item.date);
                                    return date.getFullYear() === displayYear && date.getMonth() === displayMonth;
                                })}
                                toggleDateAvailability={toggleDateAvailability}
                            />
                        );
                    }

                    return <div className="calendar-grid">{months}</div>;
                };


                const Calendar = ({ month, availability, toggleDateAvailability }) => {
                    const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
                    const firstDayOfMonth = new Date(month.getFullYear(), month.getMonth(), 1).getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                    const monthName = month.toLocaleString('default', { month: 'long' });
                    const year = month.getFullYear();

                    // Adjust firstDayOfMonth so Monday is 0 and Sunday is 6
                    const startIndex = (firstDayOfMonth + 6) % 7;

                    // Create empty cells for days before the start of the month
                    const emptyDays = [...Array(startIndex).keys()].map((_, index) => (
                        <div key={`empty-${index}`} className="day empty"></div>
                    ));

                    // Create day cells
                    const days = [...Array(daysInMonth).keys()].map(day => {
                        const date = moment.utc({ year: year, month: month.getMonth(), day: day + 1 });
                        const dateStr = date.toISOString().split('T')[0];
                        const isAvailable = availability.find(item => item.date === dateStr)?.isAvailable ?? false;

                        return (
                            <div
                                key={dateStr}
                                className={`day ${isAvailable ? 'available' : 'unavailable'}`}
                                onClick={() => toggleDateAvailability(dateStr)}
                            >
                                {day + 1}
                                {/* {!isAvailable && <span className="cross">X</span>} */}
                            </div>
                        );
                    });

                    return (
                        <div className="calendar">
                            <div className="calendar-header">
                                <span className="month-year-label">{monthName} {year}</span>
                            </div>
                            <div className="days-of-week">
                                {['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'].map(day => (
                                    <div key={day} className="day-label">{day}</div>
                                ))}
                            </div>
                            <div className="days-grid">
                                {emptyDays}
                                {days}
                            </div>
                        </div>
                    );
                };

                return (
                    <Form id={formId} className={classes} onSubmit={handleSubmit}>

                        <div className={css.infoContainer}>
                            <p className={css.info}>
                                <FormattedMessage
                                    id="EditListingAvailabilityPlanCalendarForm.infoTimezone" />
                            </p>

                            <p className={css.info}>
                                <FormattedMessage
                                    id="EditListingAvailabilityPlanCalendarForm.infoInterval" />
                            </p>
                        </div>

                        <FormattedMessage id="EditListingAvailabilityPlanCalendarForm.timezonePickerTitle" />
                        <div className={css.timezonePicker}>
                            <FieldTimeZoneSelect id="timezone" name="timezone" />
                        </div>

                        {renderCalendarGrid(currentYear, monthsToDisplay, availability, toggleDateAvailability)}

                        {updateListingError ? (
                            <p className={css.error}>
                                <FormattedMessage id="EditListingAvailabilityPlanCalendarForm.updateFailed" />
                            </p>
                        ) : null}
                        <div className={css.saveScheduleButton}>
                            <PrimaryButton
                                type="button"
                                onClick={() => handleSave(values)}
                            >
                                {!savedValues
                                    ? < FormattedMessage id="EditListingAvailabilityPlanCalendarForm.saveSchedule" />
                                    : <FormattedMessage id="EditListingAvailabilityPlanCalendarForm.saved" />
                                }
                            </PrimaryButton>
                        </div>

                        <div className='line mb20 mt20' />

                        <Button
                            className={css.submitButton}
                            type="submit"
                            inProgress={submitInProgress}
                            disabled={submitDisabled}
                        // ready={submitReady}
                        >
                            {saveActionMsg}
                        </Button>
                    </Form >
                );
            }}
        />
    );
};

EditListingAvailabilityPlanCalendarFormComponent.defaultProps = {
    rootClassName: null,
    className: null,
    submitButtonWrapperClassName: null,
    inProgress: false,
};

EditListingAvailabilityPlanCalendarFormComponent.propTypes = {
    rootClassName: string,
    className: string,
    submitButtonWrapperClassName: string,
    inProgress: bool,
    fetchErrors: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const EditListingAvailabilityPlanCalendarForm = compose(injectIntl)(
    EditListingAvailabilityPlanCalendarFormComponent
);

EditListingAvailabilityPlanCalendarForm.displayName = 'EditListingAvailabilityPlanCalendarForm';

export default EditListingAvailabilityPlanCalendarForm;
