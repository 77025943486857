export const propertyExperienceTypes = [
    { option: "DIY", label: "DIY" },
    { option: "Light gardening", label: "Light gardening" },
    { option: "Lawn care", label: "Lawn care" },
    { option: "Vegetable patch", label: "Vegetable patch" },
    { option: "Hedge management", label: "Hedge management" },
    { option: "Experienced gardener", label: "Experienced gardener" },
    { option: "Pool maintenance", label: "Pool maintenance" },
    { option: "Small holding", label: "Small holding" },
    { option: "Farm", label: "Farm" },
    { option: "Large estate", label: "Large estate" },
]