import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { H3 } from '../../../../components';

// Import modules from this directory
import EditListingHouseSitterDesiredLocationsForm from './EditListingHouseSitterDesiredLocationsForm';
import css from './EditListingHouseSitterDesiredLocationsPanel.module.css';

const getInitialValues = props => {
  const { publicData } = props.listing?.attributes || {};
  const { desiredLocations,
    locationCharacteristics,
    publicTransportAccess } = publicData;

  return {
    desiredLocations,
    locationCharacteristics,
    publicTransportAccess
  };
};

const EditListingHouseSitterDesiredLocationsPanel = props => {
  // State is needed since LocationAutocompleteInput doesn't have internal state
  // and therefore re-rendering would overwrite the values during XHR call.
  const [state, setState] = useState({ initialValues: getInitialValues(props) });
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    listingFieldsConfig,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const isPublished = listing?.id && listing?.attributes.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <H3 as="h1">
        {/* {isPublished ? (
          <FormattedMessage
            id="EditListingHouseSitterDesiredLocationsPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingHouseSitterDesiredLocationsPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
          )} */}
        <FormattedMessage id="EditListingHouseSitterDesiredLocationsPanel.createListingTitleCustom" />
      </H3>
      <EditListingHouseSitterDesiredLocationsForm
        className={css.form}
        initialValues={state.initialValues}
        onSubmit={values => {
          const { desiredLocations, locationCharacteristics, publicTransportAccess } = values;

          // New values for listing attributes
          const updateValues = {
            publicData: {
              desiredLocations,
              locationCharacteristics,
              publicTransportAccess
            },
          };

          onSubmit(updateValues);
        }}
        listingFieldsConfig={listingFieldsConfig}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingHouseSitterDesiredLocationsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingHouseSitterDesiredLocationsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingHouseSitterDesiredLocationsPanel;
