import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { H3, ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingPetsForm from './EditListingPetsForm';
import css from './EditListingPetsPanel.module.css';

const getInitialValues = props => {
  const { listing } = props;
  const { publicData } = listing?.attributes || {};

  // Only render current search if full place object is available in the URL params
  // TODO bounds are missing - those need to be queried directly from Google Places
  const { pets } = publicData || {};

  return {
    ...pets
  };
};

const EditListingPetsPanel = props => {
  // State is needed since LocationAutocompleteInput doesn't have internal state
  // and therefore re-rendering would overwrite the values during XHR call.
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    listingFieldsConfig,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const isPublished = listing?.id && listing?.attributes.state !== LISTING_STATE_DRAFT;


  return (
    <div className={classes}>
      <H3>
        {/* {isPublished ? (
          <FormattedMessage
            id="EditListingPetsPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingPetsPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
          )} */}
        <FormattedMessage id="EditListingPetsPanel.createListingTitleCustom" />
      </H3>
      <EditListingPetsForm
        className={css.form}
        initialValues={getInitialValues(props)}
        onSubmit={values => {
          const updateValues = {
            publicData: {
              pets: {
                ...values,
              }
            },
          };
          onSubmit(updateValues);
        }}
        listingFieldsConfig={listingFieldsConfig}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        autoFocus
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPetsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPetsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPetsPanel;
