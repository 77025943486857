import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Subscribe from './Subscribe'; // your Subscribe component

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

const SubscribePage = ({ location }) => {
  return (
    <Elements stripe={stripePromise}>
      <Subscribe
        location={location}
      />
    </Elements>
  );
};

export default SubscribePage;

