import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //



// ================ Reducer ================ //

const initialState = {
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {


    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

// ================ Thunk ================ //

export const updateUserSubscriptionProfile = (actionPayload) => {
  return (dispatch, getState, sdk) => {

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};
