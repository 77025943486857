export const experienceLevels = [
    { option: "basic", label: "Basic Care (e.g., feeding, cleaning)" },
    { option: "experienced", label: "Experienced Petsitter" },
    { option: "vetAssistant", label: "Veterinary Assistant" },
    { option: "trainer", label: "Animal Trainer or Behavior Specialist" },
    { option: "wildlife", label: "Wildlife Rehabilitation" },
    { option: "rescueShelter", label: "Resque Shelter Experience" },
    { option: "vetTech", label: "Certified Veterinary Technician" },
    { option: "veterinarian", label: "Licensed Veterinarian" },
]
