import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { isFieldForCategory, isFieldForListingType } from '../../../../util/fieldHelpers';
import { maxLength, required, composeValidators } from '../../../../util/validators';
import ContinueIcon from '../../../TopbarContainer/Topbar/images/continue-icon.svg';
// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  CustomExtendedDataField,
  NamedLink,
} from '../../../../components';
import css from './EditListingHouseSitHousePickerForm.module.css';
import { getDescriptionWordsLimit, getMaxHousesCount, getUserPhotosLimit, getUserSubscription, subscriptionTypes } from '../../../../util/subscriptionsHelpers';
import { getUserType, getUserTypeForSubscription, userTypes } from '../../../../util/userTypes';
import FieldListingSelect from '../../../../components/FieldChipsGroup/FieldListingSelect';

const TITLE_MAX_LENGTH = 60;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingHouseSitHousePickerForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingHouseSitHousePickerForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingHouseSitHousePickerForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const FieldSelectListingType = props => {
  const {
    name,
  } = props;

  return (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

const EditListingHouseSitHousePickerFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        form,
        form: {
          mutators: { push }
        },
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        currentUser,
        houseListings
      } = formRenderProps;

      const { listingType, transactionProcessAlias, unitType } = values;
      const maxHousesCount = getMaxHousesCount(currentUser);
      const userType = getUserTypeForSubscription(currentUser);
      const subscriptionType = getUserSubscription(currentUser);

      const isPremiumOwner = (userType === userTypes.homeowner || userType === userTypes.homeownerAndHousesitter) && subscriptionType === subscriptionTypes.premium && houseListings?.length < maxHousesCount;

      // const titleRequiredMessage = intl.formatMessage({
      //   id: 'EditListingHouseSitHousePickerForm.titleRequired',
      // });

      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingHouseSitHousePickerForm.titleRequired',
      });

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingHouseSitHousePickerForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );


      const maxDescriptionLength = getDescriptionWordsLimit(currentUser);

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxPhotosLimit = getUserPhotosLimit(currentUser);

      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const showTitle = hasCategories ? allCategoriesChosen : listingType;
      const showDescription = hasCategories ? allCategoriesChosen : listingType;
      const showListingFields = hasCategories ? allCategoriesChosen : listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData
        || !values.houseListingId;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            formId={formId}
            intl={intl}
          />

          <FieldTextInput
            id={`${formId}title`}
            name="title"
            className={css.title}
            type="text"
            label={intl.formatMessage({ id: 'EditListingHouseSitHousePickerForm.titleLabel' })}
            placeholder={intl.formatMessage({ id: 'EditListingHouseSitHousePickerForm.titlePlaceholder' })}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus={autoFocus}
          />

          <FieldListingSelect
            id={`${formId}houseListingId`}
            name="houseListingId"
            // addHouseButton={houseListings?.length < maxHousesCount}
            label={intl.formatMessage({ id: 'EditListingHouseSitHousePickerForm.houseListingLabel' })}
            listings={houseListings}
          />

          <p>
            <FormattedMessage id="EditListingHouseSitHousePickerForm.note" />
          </p>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
          {isPremiumOwner &&
            <>
              <p>
                <FormattedMessage id="EditListingHouseSitHousePickerForm.propertyNotListed" />
              </p>

              <NamedLink name="NewHouseListingPage" className={css.ctaButton}>
                <FormattedMessage id="ManageListingsPage.addNewHouseListing" />
                <img src={ContinueIcon} className={css.icon} />
              </NamedLink>
            </>
          }
        </Form>
      );
    }}
  />
);

EditListingHouseSitHousePickerFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingHouseSitHousePickerForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingHouseSitHousePickerFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingHouseSitHousePickerFormComponent);
