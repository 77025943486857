export const housesitCountries = [
    { option: "Anywhere in the world", label: "Anywhere in the world" },
    { option: "Africa", label: "Africa" },
    { option: "Asia", label: "Asia" },
    { option: "Australasia", label: "Australasia" },
    { option: "Europe", label: "Europe" },
    { option: "N America", label: "N America" },
    { option: "S America", label: "S America" },
    { option: "Afghanistan", label: "Afghanistan" },
    { option: "Albania", label: "Albania" },
    { option: "Algeria", label: "Algeria" },
    { option: "Andorra", label: "Andorra" },
    { option: "Angola", label: "Angola" },
    { option: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { option: "Argentina", label: "Argentina" },
    { option: "Armenia", label: "Armenia" },
    { option: "Australia", label: "Australia" },
    { option: "Austria", label: "Austria" },
    { option: "Azerbaijan", label: "Azerbaijan" },
    { option: "Bahamas", label: "Bahamas" },
    { option: "Bahrain", label: "Bahrain" },
    { option: "Bangladesh", label: "Bangladesh" },
    { option: "Barbados", label: "Barbados" },
    { option: "Belarus", label: "Belarus" },
    { option: "Belgium", label: "Belgium" },
    { option: "Belize", label: "Belize" },
    { option: "Benin", label: "Benin" },
    { option: "Bhutan", label: "Bhutan" },
    { option: "Bolivia", label: "Bolivia" },
    { option: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { option: "Botswana", label: "Botswana" },
    { option: "Brazil", label: "Brazil" },
    { option: "Brunei", label: "Brunei" },
    { option: "Bulgaria", label: "Bulgaria" },
    { option: "Burkina Faso", label: "Burkina Faso" },
    { option: "Burundi", label: "Burundi" },
    { option: "Cabo Verde", label: "Cabo Verde" },
    { option: "Cambodia", label: "Cambodia" },
    { option: "Cameroon", label: "Cameroon" },
    { option: "Canada", label: "Canada" },
    { option: "Central African Republic", label: "Central African Republic" },
    { option: "Chad", label: "Chad" },
    { option: "Chile", label: "Chile" },
    { option: "China", label: "China" },
    { option: "Colombia", label: "Colombia" },
    { option: "Comoros", label: "Comoros" },
    { option: "Congo", label: "Congo" },
    { option: "Costa Rica", label: "Costa Rica" },
    { option: "Croatia", label: "Croatia" },
    { option: "Cuba", label: "Cuba" },
    { option: "Cyprus", label: "Cyprus" },
    { option: "Czech Republic", label: "Czech Republic" },
    { option: "Denmark", label: "Denmark" },
    { option: "Djibouti", label: "Djibouti" },
    { option: "Dominica", label: "Dominica" },
    { option: "Dominican Republic", label: "Dominican Republic" },
    { option: "Ecuador", label: "Ecuador" },
    { option: "Egypt", label: "Egypt" },
    { option: "El Salvador", label: "El Salvador" },
    { option: "Equatorial Guinea", label: "Equatorial Guinea" },
    { option: "Eritrea", label: "Eritrea" },
    { option: "Estonia", label: "Estonia" },
    { option: "Eswatini", label: "Eswatini" },
    { option: "Ethiopia", label: "Ethiopia" },
    { option: "Fiji", label: "Fiji" },
    { option: "Finland", label: "Finland" },
    { option: "France", label: "France" },
    { option: "Gabon", label: "Gabon" },
    { option: "Gambia", label: "Gambia" },
    { option: "Georgia", label: "Georgia" },
    { option: "Germany", label: "Germany" },
    { option: "Ghana", label: "Ghana" },
    { option: "Greece", label: "Greece" },
    { option: "Grenada", label: "Grenada" },
    { option: "Guatemala", label: "Guatemala" },
    { option: "Guinea", label: "Guinea" },
    { option: "Guinea-Bissau", label: "Guinea-Bissau" },
    { option: "Guyana", label: "Guyana" },
    { option: "Haiti", label: "Haiti" },
    { option: "Honduras", label: "Honduras" },
    { option: "Hungary", label: "Hungary" },
    { option: "Iceland", label: "Iceland" },
    { option: "India", label: "India" },
    { option: "Indonesia", label: "Indonesia" },
    { option: "Iran", label: "Iran" },
    { option: "Iraq", label: "Iraq" },
    { option: "Ireland", label: "Ireland" },
    { option: "Israel", label: "Israel" },
    { option: "Italy", label: "Italy" },
    { option: "Jamaica", label: "Jamaica" },
    { option: "Japan", label: "Japan" },
    { option: "Jordan", label: "Jordan" },
    { option: "Kazakhstan", label: "Kazakhstan" },
    { option: "Kenya", label: "Kenya" },
    { option: "Kiribati", label: "Kiribati" },
    { option: "Kosovo", label: "Kosovo" },
    { option: "Kuwait", label: "Kuwait" },
    { option: "Kyrgyzstan", label: "Kyrgyzstan" },
    { option: "Laos", label: "Laos" },
    { option: "Latvia", label: "Latvia" },
    { option: "Lebanon", label: "Lebanon" },
    { option: "Lesotho", label: "Lesotho" },
    { option: "Liberia", label: "Liberia" },
    { option: "Libya", label: "Libya" },
    { option: "Liechtenstein", label: "Liechtenstein" },
    { option: "Lithuania", label: "Lithuania" },
    { option: "Luxembourg", label: "Luxembourg" },
    { option: "Madagascar", label: "Madagascar" },
    { option: "Malawi", label: "Malawi" },
    { option: "Malaysia", label: "Malaysia" },
    { option: "Maldives", label: "Maldives" },
    { option: "Mali", label: "Mali" },
    { option: "Malta", label: "Malta" },
    { option: "Marshall Islands", label: "Marshall Islands" },
    { option: "Mauritania", label: "Mauritania" },
    { option: "Mauritius", label: "Mauritius" },
    { option: "Mexico", label: "Mexico" },
    { option: "Micronesia", label: "Micronesia" },
    { option: "Moldova", label: "Moldova" },
    { option: "Monaco", label: "Monaco" },
    { option: "Mongolia", label: "Mongolia" },
    { option: "Montenegro", label: "Montenegro" },
    { option: "Morocco", label: "Morocco" },
    { option: "Mozambique", label: "Mozambique" },
    { option: "Myanmar", label: "Myanmar" },
    { option: "Namibia", label: "Namibia" },
    { option: "Nauru", label: "Nauru" },
    { option: "Nepal", label: "Nepal" },
    { option: "Netherlands", label: "Netherlands" },
    { option: "New Zealand", label: "New Zealand" },
    { option: "Nicaragua", label: "Nicaragua" },
    { option: "Niger", label: "Niger" },
    { option: "Nigeria", label: "Nigeria" },
    { option: "North Korea", label: "North Korea" },
    { option: "North Macedonia", label: "North Macedonia" },
    { option: "Norway", label: "Norway" },
    { option: "Oman", label: "Oman" },
    { option: "Pakistan", label: "Pakistan" },
    { option: "Palau", label: "Palau" },
    { option: "Palestine", label: "Palestine" },
    { option: "Panama", label: "Panama" },
    { option: "Papua New Guinea", label: "Papua New Guinea" },
    { option: "Paraguay", label: "Paraguay" },
    { option: "Peru", label: "Peru" },
    { option: "Philippines", label: "Philippines" },
    { option: "Poland", label: "Poland" },
    { option: "Portugal", label: "Portugal" },
    { option: "Qatar", label: "Qatar" },
    { option: "Romania", label: "Romania" },
    { option: "Russia", label: "Russia" },
    { option: "Rwanda", label: "Rwanda" },
    { option: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { option: "Saint Lucia", label: "Saint Lucia" },
    { option: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { option: "Samoa", label: "Samoa" },
    { option: "San Marino", label: "San Marino" },
    { option: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { option: "Saudi Arabia", label: "Saudi Arabia" },
    { option: "Senegal", label: "Senegal" },
    { option: "Serbia", label: "Serbia" },
    { option: "Seychelles", label: "Seychelles" },
    { option: "Sierra Leone", label: "Sierra Leone" },
    { option: "Singapore", label: "Singapore" },
    { option: "Slovakia", label: "Slovakia" },
    { option: "Slovenia", label: "Slovenia" },
    { option: "Solomon Islands", label: "Solomon Islands" },
    { option: "Somalia", label: "Somalia" },
    { option: "South Africa", label: "South Africa" },
    { option: "South Korea", label: "South Korea" },
    { option: "South Sudan", label: "South Sudan" },
    { option: "Spain", label: "Spain" },
    { option: "Sri Lanka", label: "Sri Lanka" },
    { option: "Sudan", label: "Sudan" },
    { option: "Suriname", label: "Suriname" },
    { option: "Sweden", label: "Sweden" },
    { option: "Switzerland", label: "Switzerland" },
    { option: "Syria", label: "Syria" },
    { option: "Taiwan", label: "Taiwan" },
    { option: "Tajikistan", label: "Tajikistan" },
    { option: "Tanzania", label: "Tanzania" },
    { option: "Thailand", label: "Thailand" },
    { option: "Timor-Leste", label: "Timor-Leste" },
    { option: "Togo", label: "Togo" },
    { option: "Tonga", label: "Tonga" },
    { option: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { option: "Tunisia", label: "Tunisia" },
    { option: "Turkey", label: "Turkey" },
    { option: "Turkmenistan", label: "Turkmenistan" },
    { option: "Tuvalu", label: "Tuvalu" },
    { option: "Uganda", label: "Uganda" },
    { option: "Ukraine", label: "Ukraine" },
    { option: "United Arab Emirates", label: "United Arab Emirates" },
    { option: "United Kingdom", label: "United Kingdom" },
    { option: "United States", label: "United States" },
    { option: "Uruguay", label: "Uruguay" },
    { option: "Uzbekistan", label: "Uzbekistan" },
    { option: "Vanuatu", label: "Vanuatu" },
    { option: "Vatican City", label: "Vatican City" },
    { option: "Venezuela", label: "Venezuela" },
    { option: "Vietnam", label: "Vietnam" },
    { option: "Yemen", label: "Yemen" },
    { option: "Zambia", label: "Zambia" },
    { option: "Zimbabwe", label: "Zimbabwe" }
]