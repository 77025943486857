import React, { useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../../../util/dates';
import { LISTING_STATE_DRAFT, propTypes } from '../../../../util/types';
import { DAY, isFullDay } from '../../../../transactions/transaction';

// Import shared components
import { Button, H3, InlineTextButton, ListingLink, Modal } from '../../../../components';

import css from './EditListingAvailabilityPanel.module.css';
import EditListingAvailabilityPlanCalendarForm from './EditListingAvailabilityPlanCalendarForm/EditListingAvailabilityPlanCalendarForm';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

const createEntryDayGroups = (entries = {}) => {
  let activePlanDays = [];
  return entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    activePlanDays = activePlanDays.includes(dayOfWeek)
      ? activePlanDays
      : [...activePlanDays, dayOfWeek];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
      activePlanDays,
    };
  }, {});
};

const createInitialValues = (availabilityPlan, availabilityInterval) => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
    start: availabilityInterval?.start,
    end: availabilityInterval?.end
  };
};

const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    allExceptions,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    updateInProgress,
    errors,
    panelUpdated,
  } = props;
  // Hooks
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);

  const classes = classNames(rootClassName || css.root, className);
  const listingAttributes = listing?.attributes;
  const unitType = listingAttributes?.publicData?.unitType;
  const useFullDays = isFullDay(unitType);
  const isPublished = listing?.id && listingAttributes?.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 10 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 10 },
    ],
  };
  const availabilityPlan = listingAttributes?.availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan, listingAttributes.publicData.availabilityInterval);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);
    const availabilityPlan = defaultAvailabilityPlan;
    availabilityPlan.timezone = values.timezone;

    return onSubmit({
      availabilityPlan: availabilityPlan,
      publicData: { availabilityInterval: values.availabilityInterval }
    })
      .then(async (avResponse) => {
        try {
          if (allExceptions[0]) {
            await onDeleteAvailabilityException({ id: allExceptions[0].id.uuid });
          }

          if (allExceptions[1]) {
            await onDeleteAvailabilityException({ id: allExceptions[1].id.uuid });
          }

          // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
          // await delay(3000);

          if (values.exceptionsBeforeInterval) {
            await saveException({ exceptionRange: { startDate: values.exceptionsBeforeInterval.start, endDate: values.exceptionsBeforeInterval.end } });
          }

          if (values.exceptionsAfterInterval) {
            await saveException({ exceptionRange: { startDate: values.exceptionsAfterInterval.start, endDate: values.exceptionsAfterInterval.end } });
          }

        } catch (e) {
          console.error('Error:', e);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };


  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime, exceptionRange } = values;

    const seats = availability === 'available' ? 1 : 0;

    const range = useFullDays
      ? {
        start: exceptionRange?.startDate,
        end: exceptionRange?.endDate,
      }
      : {
        start: timestampToDate(exceptionStartTime),
        end: timestampToDate(exceptionEndTime),
      };

    const params = {
      listingId: listing.id,
      seats,
      ...range,
    };

    return onAddAvailabilityException(params)
  };

  return (
    <main className={classes}>
      {/* <H3 as="h1">
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingAvailabilityPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
        )}
      </H3> */}
      <H3>
        <FormattedMessage id="EditListingAvailabilityPanel.availability" />
      </H3>

      {errors.showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
        </p>
      ) : null}

      <EditListingAvailabilityPlanCalendarForm
        formId="EditListingAvailabilityPlanCalendarForm"
        availabilityPlan={availabilityPlan}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        allExceptions={allExceptions}
        inProgress={updateInProgress}
        fetchErrors={errors}
        listing={listing}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  monthlyExceptionQueries: null,
  weeklyExceptionQueries: null,
  allExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  monthlyExceptionQueries: object,
  weeklyExceptionQueries: object,
  allExceptions: arrayOf(propTypes.availabilityException),
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
