import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import arrow from './arrow.png';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

// Import configs and util modules
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';

// Import shared components
import {
  Button,
  FieldMultipleItems,
  FieldSingleSelect,
  Form
} from '../../../../components';

// Import modules from this directory
import { housesitCountries } from '../../../../util/housesitCountries';
import css from './EditListingHouseSitterDesiredLocationsForm.module.css';

export const EditListingHouseSitterDesiredLocationsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
      } = formRenderProps;
      const [isDesiredLocationsOpen, setIsDesiredLocationsOpen] = useState(false);

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const locationCharacteristicsOptions = listingFieldsConfig?.find(c => c.key === 'locationCharacteristics')?.enumOptions;
      const locationCharacteristicsLabel = listingFieldsConfig?.find(c => c.key === 'locationCharacteristics').showConfig.label;

      const publicTransportAccessOptions = listingFieldsConfig?.find(c => c.key === 'publicTransportAccess')?.enumOptions;

      const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
      const isMobileLayout = hasMatchMedia
        ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
        : true;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterDesiredLocationsForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterDesiredLocationsForm.showListingFailed" />
            </p>
          ) : null}

          <div
            className={css.dropdown}
            onMouseEnter={!isMobileLayout ? () => setIsDesiredLocationsOpen(true) : undefined}
            onMouseLeave={!isMobileLayout ? () => setIsDesiredLocationsOpen(false) : undefined}
          >
            <label>
              <FormattedMessage id="EditListingHouseSitterDesiredLocationsForm.desiredLocations" />
            </label>

            <div className={css.dropdownButton}
              onClick={isMobileLayout ? () => setIsDesiredLocationsOpen((prev) => !prev) : undefined}
            >
              {values.desiredLocations ?
                values.desiredLocations.join(', ')
                :
                <FormattedMessage id="EditListingHouseSitterDesiredLocationsForm.desiredLocationsPlaceholder" />
              }

              <img src={arrow} alt="Arrow" className={!isDesiredLocationsOpen ? css.arrow : css.arrowRotated} />
            </div>
            {isDesiredLocationsOpen && (
              <div
                className={css.dropdownContent}
                onMouseEnter={!isMobileLayout ? () => setIsDesiredLocationsOpen(true) : undefined}
                onMouseLeave={!isMobileLayout ? () => setIsDesiredLocationsOpen(false) : undefined}
              >

                <FieldMultipleItems
                  className={css.fieldClass}
                  name="desiredLocations"
                  id={`${formId}desiredLocations`}
                  label={locationCharacteristicsLabel}
                  options={housesitCountries}
                />
              </div>
            )}
          </div>

          <FieldMultipleItems
            className={css.fieldClass}
            name="locationCharacteristics"
            id={`${formId}locationCharacteristics`}
            label={intl.formatMessage({ id: "EditListingHouseSitterDesiredLocationsForm.locationCharacteristics" })}
            options={locationCharacteristicsOptions}
          />

          <FieldSingleSelect
            className={css.fieldClass}
            name="publicTransportAccess"
            id={`${formId}publicTransportAccess`}
            label={intl.formatMessage({ id: "EditListingHouseSitterDesiredLocationsForm.publicTransportAccess" })}
            options={publicTransportAccessOptions}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingHouseSitterDesiredLocationsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'EditListingHouseSitterDesiredLocationsForm',
};

EditListingHouseSitterDesiredLocationsFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingHouseSitterDesiredLocationsFormComponent);
