export const userTypes = {
    homeowner: "homeowner",
    housesitter: "housesitter",
    homeownerAndHousesitter: "homeownerAndHousesitter"
}

export const getUserType = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.publicData.userType;
}


export const getUserTypeForDisplay = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.publicData.userType;
}


export const getUserTypeForSubscription = (currentUser) => {
    if (!currentUser) return null;

    const isCombinedAccount = getIsCombinedUserType(currentUser);
    return isCombinedAccount ? userTypes.homeownerAndHousesitter : currentUser.attributes.profile.publicData.userType;
}

export const getIsCombinedUserType = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.publicData.isCombinedAccount;
}

export const getIsHouseSitter = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.publicData.userType === userTypes.housesitter
    // || currentUser.attributes.profile.publicData.userType === userTypes.homeownerAndHousesitter;
}

export const getIsHouseOwner = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.publicData.userType === userTypes.homeowner
    // ||       currentUser.attributes.profile.publicData.userType === userTypes.homeownerAndHousesitter;
}

export const getIsStripeVerified = (currentUser) => {
    if (!currentUser) return null;

    return currentUser?.attributes?.profile?.publicData?.isVerified;
}

export const getIsVerifilePoliceCheckingVerified = (currentUser) => {
    return false;
}

export const getProfileListingId = (currentUser) => {
    if (!currentUser) return null;

    return currentUser?.attributes?.profile?.publicData?.listingId;
}
export const getProfileListingTitle = (currentUser) => {
    if (!currentUser) return null;

    return currentUser?.attributes?.profile?.publicData?.listingTitle;
}