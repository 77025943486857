import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';
import { FieldArray } from 'react-final-form-arrays';
// Import configs and util modules
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';

// Import shared components
import {
  Button,
  FieldMultipleItems,
  FieldSelect,
  FieldSingleSelect,
  FieldTextInput,
  Form
} from '../../../../components';

// Import modules from this directory
import css from './EditListingHouseSitterPetCareExperienceForm.module.css';
import { propertyExperienceTypes } from '../../../../util/propertyExperienceTypes';
import { availableForCommunication } from '../../../../util/availableForCommunication';
import { pets } from '../../../../util/pets';
import { required } from '../../../../util/validators';
import { experienceLevels } from '../../../../util/experienceLevels';

export const EditListingHouseSitterPetCareExperienceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        form,
        form: formApi,
        form: {
          mutators: { push }
        },
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const availableForCommunicationLabel = <div><label >
        <FormattedMessage id={"EditListingHouseSitterPetCareExperienceForm.availableForCommunication"} />
      </label>
        <label className={css.info}>
          <FormattedMessage id={"EditListingHouseSitterPetCareExperienceForm.availableForCommunicationInfo"} />
        </label></div>

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterPetCareExperienceForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterPetCareExperienceForm.showListingFailed" />
            </p>
          ) : null}


          <div className='col gap20'>

            <label>
              <FormattedMessage id="EditListingHouseSitterPetCareExperienceForm.petCareExperience" />
            </label>

            <FieldArray name="petCareExperience">
              {({ fields }) => fields.map((name, index) => {
                const value = values.petCareExperience[index]
                const handleDelete = () => {
                  fields.remove(index);
                }

                return (
                  <div className='row gap20 center width-full'>

                    <FieldSelect
                      id={`${name}petType`}
                      name={`${name}petType`}
                      className='halfWidth'
                      label={index === 0 ? intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.petType' }) : null}
                      validate={required(intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.petTypeRequired' },)
                      )}
                    >
                      <option disabled value="">
                        {intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.petTypePlaceholder' })}
                      </option>
                      <option key={"ALL PETS"} value={"ALL PETS"}>
                        ALL PETS
                      </option>
                      {pets.map(option => (
                        <option key={option.option} value={option.option}>
                          {option.label}
                        </option>
                      ))}
                    </FieldSelect>

                    <FieldSelect
                      id={`${name}experienceLevel`}
                      name={`${name}experienceLevel`}
                      className='halfWidth'
                      label={index === 0 ? intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.experienceLevel' },) : null}
                      validate={required(intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.experienceLevelRequired' },)
                      )}
                    >
                      <option disabled value="">
                        {intl.formatMessage({ id: 'EditListingHouseSitterPetCareExperienceForm.experienceLevelPlaceholder' })}
                      </option>

                      {experienceLevels.map(option => (
                        <option key={option.option} value={option.option}>
                          {option.label}
                        </option>
                      ))}
                    </FieldSelect>

                    {values.petCareExperience && values.petCareExperience[0] != '' &&
                      <div className='row' onClick={handleDelete}>
                        <p className={index === 0 ? css.deleteButtonFirst : css.deleteButton} >
                          <FormattedMessage id="EditListingHouseSitterPetCareExperienceForm.delete" />
                        </p>
                      </div>
                    }
                  </div>
                )
              })}
            </FieldArray>


            <div className='row' onClick={(e) => {
              e.preventDefault();
              push('petCareExperience', '');
            }}>
              <p className={css.addButton} >
                <FormattedMessage id="EditListingHouseSitterPetCareExperienceForm.add" />
              </p>
            </div>

          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingHouseSitterPetCareExperienceFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'EditListingHouseSitterPetCareExperienceForm',
};

EditListingHouseSitterPetCareExperienceFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingHouseSitterPetCareExperienceFormComponent);
