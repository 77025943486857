import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldSingleSelect.module.css';
import ValidationError from '../ValidationError/ValidationError';

const FieldSingleSelectComponent = props => {
    const {
        rootClassName,
        className,
        label,
        options,
        name,
        input,
        meta,
    } = props;

    const handleOnClick = (input, value) => {
        input.onChange(value);
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
        <div className={classes}>
            {label ? <label>{label}</label> : null}

            <div
                className={css.grid2Columns}
                onClick={() => input.onBlur()}
            >
                {options?.map(option => {
                    const isSelected = input.value === option.option;

                    return (
                        <button
                            key={option.option}
                            type="button"
                            className={isSelected ? css.activeItem : css.inactiveItem}
                            onClick={() => handleOnClick(input, option.option)}
                            onMouseEnter={() => input.onFocus()}

                        >
                            <label>
                                {option.label}
                            </label>
                        </button>
                    );
                })}
            </div>

            <ValidationError fieldMeta={{ ...meta }} />
        </div>
    );
};

const FieldSingleSelect = props => {
    return <Field component={FieldSingleSelectComponent} {...props} />;
};

export default FieldSingleSelect;