import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { Redirect } from 'react-router-dom';
import { H2, SavedCardDetails, Page, UserNav, LayoutSideNavigation } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import css from './StripeSubscriptions.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import { post } from '../../util/api';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const { UUID, LatLng } = sdkTypes;

// this create customer if not existing
const getSubscriptions = (setIsStripeConnected, setSubscriptions) => {
  sdk.currentUser
    .show({
      include: ['stripeAccount', 'stripeCustomer'],
    })
    .then(resp => {
      let isConnected = resp.data.data.attributes.stripeConnected;
      const userEmail = resp.data.data.attributes.email;
      const userId = resp.data.data.id.uuid;
      let hasCustomerId = resp.data.data.attributes.profile.privateData
        ? resp.data.data.attributes.profile.privateData.stripeCustomerId
          ? resp.data.data.attributes.profile.privateData.stripeCustomerId
          : null
        : null;
      setIsStripeConnected(isConnected);

      // special case, treat it the same the imported one
      if (typeof resp.data.data.attributes.profile.privateData.id === 'number') {
        isConnected = true;
        hasCustomerId = resp.data.data.attributes.profile.privateData.subscriptions[0].customerId;
      }

      return [hasCustomerId, userEmail, userId];
    })
    .then(resp => {
      const stripeCustomerId = resp[0];
      const userEmail = resp[1];
      const userId = resp[2];

      if (stripeCustomerId) {
        const requestOptions = {
          stripeCustomerId: stripeCustomerId, //"cus_ROl5SMKsIBz31V" //stripeCustomerId, // for test, emipasat@yahoo.com
        };
        // test restart

        const fetchData = async () => {
          const { subscriptions } = await post('/api/subscriptions-list', requestOptions);

          setSubscriptions(subscriptions.data);
        };
        fetchData();
      } else {
        post('/api/create-customer', {
          email: userEmail,
          userId: userId,
        }).catch(error => console.log(error));

        setSubscriptions([]);
      }
    })
    .catch(e => console.log(e));
};

const AccountSubscription = ({ subscription }) => {
  return (
    <section>
      <hr />
      <h4>
        {subscription.plan.amount / 100} {subscription.plan.currency.toUpperCase()} / {subscription.plan.interval}
      </h4>
      <p>Status: {subscription.status}</p>
      <p>Current period end: {new Date(subscription.current_period_end * 1000).toString()}</p>
      {!subscription.cancel_at_period_end ? (<Link to={{ pathname: '/account/cancel-subscription', state: { subscription: subscription.id } }}>Cancel</Link>) : <b>Cancelled</b>}

    </section>
  );
};

const SubscriptionsPage = props => {
  const { currentTab, scrollingDisabled } = props;

  const [subscriptions, setSubscriptions] = useState([]);
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [isProvider, setIsProvider] = useState(true);

  const [redirectToPrices, setRedirectToPrices] = useState(false);



  useEffect(() => {
    getSubscriptions(setIsStripeConnected, setSubscriptions);
  }, []);

  const hasSubscriptions = subscriptions.length !== 0;

  // useEffect(() => {
  //   sdk.currentUser
  //     .show({
  //       include: ['stripeAccount', 'stripeCustomer'],
  //     })
  //     .then(resp => {
  //       const hasCustomerId = resp.data.data.attributes.profile.privateData
  //         ? resp.data.data.attributes.profile.privateData.stripeCustomerId
  //           ? resp.data.data.attributes.profile.privateData.stripeCustomerId
  //           : null
  //         : null;
  //       const isConnected = resp.data.data.attributes.stripeConnected;
  //       setIsStripeConnected(isConnected);

  //       setIsProvider(resp.data.data.attributes?.profile?.publicData?.userType === 'service-provider');

  //       if (hasCustomerId) {
  //         const stripeCustomerId = resp.data.data.attributes.profile.privateData.stripeCustomerId;
  //         const requestOptions = {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             stripeCustomerId: stripeCustomerId,
  //           }),
  //         };

  //         post('/api/subscriptions-list', requestOptions)
  //           .then(resp => {
  //             const subscriptions = resp.subscriptions.data.filter(s => s.customer === stripeCustomerId);
  //             setSubscriptions(subscriptions);
  //           })
  //           .catch(error => {
  //             console.log(error);
  //           });
  //       } else {
  //         setSubscriptions([]);
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // }, []);

  // if (!subscriptions || subscriptions.length === 0) {
  //   sdk.currentUser
  //     .show({
  //       include: ['stripeAccount', 'stripeCustomer'],
  //     })
  //     .then(resp => {
  //       const hasCustomerId = resp.data.data.attributes.profile.privateData
  //         ? resp.data.data.attributes.profile.privateData.stripeCustomerId
  //           ? resp.data.data.attributes.profile.privateData.stripeCustomerId
  //           : null
  //         : null;
  //       const isConnected = resp.data.data.attributes.stripeConnected;
  //       setIsStripeConnected(isConnected);

  //       setIsProvider(resp.data.data.attributes?.profile?.publicData?.userType === 'service-provider');

  //       if (hasCustomerId) {
  //         const stripeCustomerId = resp.data.data.attributes.profile.privateData.stripeCustomerId;
  //         const requestOptions = {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({
  //             stripeCustomerId: stripeCustomerId,
  //           }),
  //         };

  //         post('/api/subscriptions-list', requestOptions)
  //           .then(resp => {
  //             const subscriptions = resp.subscriptions.data.filter(s => s.customer === stripeCustomerId);
  //             if (subscriptions && subscriptions.length !== 0) {
  //               setSubscriptions(subscriptions);
  //             } else {
  //               //return <Redirect to="/prices" />;
  //               setRedirectToPrices(true);
  //             }
  //           })
  //           .catch(error => {
  //             console.log(error);
  //           });
  //       } else {
  //         //return <Redirect to="/account/prices" />;
  //         setRedirectToPrices(true);
  //       }
  //     })
  //     .catch(e => {
  //       console.log(e);
  //     });
  // }

  // TODO stupid, create customer and then redirect to prices. sometimes custoemr is not created?
  if (redirectToPrices) {
    return <Redirect to="/account/prices" />;
  }

  const title = 'Subscriptions';

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation
        topbar={
          <>
            <TopbarContainer
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav currentPage="SubscriptionsPage" />
          </>
        }
        sideNav={null}
        useAccountSettingsNav
        currentPage="SubscriptionsPage"
        footer={
          <FooterContainer />
        }
      >
        <div className={css.content}>
          <H2 as="h1">
            {/* <FormattedMessage id="Account.heading" /> */}
            Subscriptions
          </H2>
          {subscriptions.map(subscription => (
            <AccountSubscription key={subscription.id} subscription={subscription} />
          ))}
        </div>
      </LayoutSideNavigation>
    </Page>
  );
};

export default withRouter(SubscriptionsPage);