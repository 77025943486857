import React, { useState, useEffect } from 'react';
import { Page, H3, H4, TabNav, Button, LayoutSideNavigation } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { Redirect } from 'react-router-dom';
import { post, get } from '../../util/api';
//import css from './StripeSubscriptions.module.css';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import css from './Prices.module.css';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const Prices = (props) => { //({ prices, createSubscription }) => {

  const title = 'Prices';
  const tabs = [];
  const { currentTab, scrollingDisabled } = props;

  const [prices, setPrices] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(false);
  const [userName, setUserName] = useState('');
  const [subscriptions, setSubscriptions] = useState([]); // not false!
  const [warning, setWarning] = useState(false);
  const [userPrivateData, setUserPrivateData] = useState(false);

  useEffect(() => {
    const fetchPrices = async () => {
      const { prices } = await get('/api/get-subscription-prices');
      setPrices(prices);
    };
    fetchPrices();

    sdk.currentUser
      .show({
        include: ['stripeAccount', 'stripeCustomer'],
      })
      .then(resp => {
        const privateData = resp.data.data.attributes.profile.privateData;
        const hasCustomerId = privateData
          ? privateData.stripeCustomerId
            ? privateData.stripeCustomerId
            : null
          : null;
        const lastName = resp.data.data.attributes.profile.lastName;
        const firstName = resp.data.data.attributes.profile.firstName;
        setUserPrivateData(privateData);
        setUserName(`${lastName} ${firstName}`);
        if (hasCustomerId) {
          setStripeCustomerId(hasCustomerId);
        }

        if (privateData && privateData.subscriptions) {
          setSubscriptions(privateData.subscriptions);
        }
      })
      .catch(e => console.log(e));
  }, []);

  const createSubscription = async priceId => {

    if (userName === '' || !userName) {
      // redirect to signup
      return props.history.push('/signup');
    }

    if (subscriptions && subscriptions.length !== 0) {
      if (!subscriptions.includes(priceId)) {
        const { subscriptionId, clientSecret } = await post('/api/create-subscription', {
          priceId,
          stripeCustomerId: stripeCustomerId,
        }).catch(e => console.log('error in creating subscription'));

        setSubscriptionData({ subscriptionId, clientSecret, priceId, userName, userPrivateData });
      } else {
        setWarning(true);
      }
    } else {
      // TODO cand vine prima data in Prices, nu am stripeCustomerId
      // trebuie refresh. ca sa rezolv, mai bine iau inca o data?

      const resp11 = await sdk.currentUser
        .show({
          include: ['stripeAccount', 'stripeCustomer'],
        });
      const privateData = resp11.data.data.attributes.profile.privateData;
      const hasCustomerId = privateData
        ? privateData.stripeCustomerId
          ? privateData.stripeCustomerId
          : null
        : null;
      if (hasCustomerId) {
        //setStripeCustomerId(privateData.stripeCustomerId);
        // TODO why is false here??

        const { subscriptionId, clientSecret } = await post('/api/create-subscription', {
          priceId,
          stripeCustomerId: hasCustomerId, //stripeCustomerId,
        }).catch(e => console.log('error in creating subscription'));

        setSubscriptionData({ subscriptionId, clientSecret, priceId, userName, userPrivateData, subscriptions });
      }
    }
  };

  if (subscriptionData) {

    console.log(subscriptionData)
    // TODO if we are in prices and just click, it already create the subscription as incomplete!!!!!
    // how to test? to the link under wrong, like just /subscribe !!!!!! and  you'll see

    // TODO la /account/subscribe no header, no tabs, no page layout
    return (
      <Redirect
        to={{
          pathname: '/account/subscribe',
          state: subscriptionData
        }}
      />
    );
  }


  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation
        sideNavClassName={css.navigation}
        topbar={
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="StripeAccountSubscriptionsPage"
          />
        }
        sideNav={
          <>
            <H3 as="h1" className={css.title}>
              Prices
            </H3>
            <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />{' '}
          </>
        }
        footer={<FooterContainer />}
      >
        <h4>Select a plan</h4>
        <div className={css.content}>

          {prices.map((price) => (
            <div key={price.id} className={css.planOption}>
              <h2>{price.nickname}</h2>
              <p>{`GBP ${price.unit_amount / 100} / ${price.recurring.interval}`}</p>
              <Button onClick={() => createSubscription(price.id)}>Select</Button>
            </div>
          ))}
        </div>
      </LayoutSideNavigation>
    </Page>
  );
};

export default Prices;