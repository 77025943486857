import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { isFieldForCategory, isFieldForListingType } from '../../../../util/fieldHelpers';
import { maxLength, required, composeValidators, maxWords } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  CustomExtendedDataField,
} from '../../../../components';
import FieldAzureImageUpload from '../../../../components/FieldAzureImageUpload/FieldAzureImageUpload';
// Import modules from this directory
import css from './EditListingDetailsForm.module.css';
import { getDescriptionWordsLimit, getUserPhotosLimit } from '../../../../util/subscriptionsHelpers';
import { FieldArray } from 'react-final-form-arrays';
import { wordsCount } from '../../../../util/wordsHelpers';

const TITLE_MAX_LENGTH = 60;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

// Field component that either allows selecting listing type (if multiple types are available)
// or just renders hidden fields:
// - listingType              Set of predefined configurations for each listing type
// - transactionProcessAlias  Initiate correct transaction against Marketplace API
// - unitType                 Main use case: pricing unit
const FieldSelectListingType = props => {
  const {
    name,
    listingTypes,
    hasExistingListingType,
    onListingTypeChange,
    formApi,
    formId,
    intl,
  } = props;
  const hasMultipleListingTypes = listingTypes?.length > 1;

  const handleOnChange = value => {
    const selectedListingType = listingTypes.find(config => config.listingType === value);
    formApi.change('transactionProcessAlias', selectedListingType.transactionProcessAlias);
    formApi.change('unitType', selectedListingType.unitType);

    if (onListingTypeChange) {
      onListingTypeChange(selectedListingType);
    }
  };
  const getListingTypeLabel = listingType => {
    const listingTypeConfig = listingTypes.find(config => config.listingType === listingType);
    return listingTypeConfig ? listingTypeConfig.label : listingType;
  };

  // return hasMultipleListingTypes && !hasExistingListingType ? (
  //   <>
  //     <FieldSelect
  //       id={formId ? `${formId}.${name}` : name}
  //       name={name}
  //       className={css.listingTypeSelect}
  //       label={intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
  //       validate={required(
  //         intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeRequired' })
  //       )}
  //       onChange={handleOnChange}
  //     >
  //       <option disabled value="">
  //         {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypePlaceholder' })}
  //       </option>
  //       {listingTypes.map(config => {
  //         const type = config.listingType;
  //         return (
  //           <option key={type} value={type}>
  //             {config.label}
  //           </option>
  //         );
  //       })}
  //     </FieldSelect>
  //     <FieldHidden name="transactionProcessAlias" />
  //     <FieldHidden name="unitType" />
  //   </>
  // ) : hasMultipleListingTypes && hasExistingListingType ? (
  //   <div className={css.listingTypeSelect}>
  //     <Heading as="h5" rootClassName={css.selectedLabel}>
  //       {intl.formatMessage({ id: 'EditListingDetailsForm.listingTypeLabel' })}
  //     </Heading>
  //     <p className={css.selectedValue}>{getListingTypeLabel(formApi.getFieldState(name)?.value)}</p>
  //     <FieldHidden name={name} />
  //     <FieldHidden name="transactionProcessAlias" />
  //     <FieldHidden name="unitType" />
  //   </div>
  // ) : 
  return (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

// Finds the correct subcategory within the given categories array based on the provided categoryIdToFind.
const findCategoryConfig = (categories, categoryIdToFind) => {
  return categories?.find(category => category.id === categoryIdToFind);
};

/**
 * Recursively render subcategory field inputs if there are subcategories available.
 * This function calls itself with updated props to render nested category fields.
 * The select field is used for choosing a category or subcategory.
 */
const CategoryField = props => {
  const { currentCategoryOptions, level, values, prefix, handleCategoryChange, intl } = props;

  const currentCategoryKey = `${prefix}${level}`;

  const categoryConfig = findCategoryConfig(currentCategoryOptions, values[`${prefix}${level}`]);

  return (
    <>
      {currentCategoryOptions ? (
        <FieldSelect
          key={currentCategoryKey}
          id={currentCategoryKey}
          name={currentCategoryKey}
          className={css.listingTypeSelect}
          onChange={event => handleCategoryChange(event, level, currentCategoryOptions)}
          label={intl.formatMessage(
            { id: 'EditListingDetailsForm.categoryLabel' },
            { categoryLevel: currentCategoryKey }
          )}
          validate={required(
            intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryRequired' },
              { categoryLevel: currentCategoryKey }
            )
          )}
        >
          <option disabled value="">
            {intl.formatMessage(
              { id: 'EditListingDetailsForm.categoryPlaceholder' },
              { categoryLevel: currentCategoryKey }
            )}
          </option>

          {currentCategoryOptions.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </FieldSelect>
      ) : null}

      {categoryConfig?.subcategories?.length > 0 ? (
        <CategoryField
          currentCategoryOptions={categoryConfig.subcategories}
          level={level + 1}
          values={values}
          prefix={prefix}
          handleCategoryChange={handleCategoryChange}
          intl={intl}
        />
      ) : null}
    </>
  );
};

const FieldSelectCategory = props => {
  useEffect(() => {
    checkIfInitialValuesExist();
  }, []);

  const { prefix, listingCategories, formApi, intl, setAllCategoriesChosen, values } = props;

  // Counts the number of selected categories in the form values based on the given prefix.
  const countSelectedCategories = () => {
    return Object.keys(values).filter(key => key.startsWith(prefix)).length;
  };

  // Checks if initial values exist for categories and sets the state accordingly.
  // If initial values exist, it sets `allCategoriesChosen` state to true; otherwise, it sets it to false
  const checkIfInitialValuesExist = () => {
    const count = countSelectedCategories(values, prefix);
    setAllCategoriesChosen(count > 0);
  };

  // If a parent category changes, clear all child category values
  const handleCategoryChange = (category, level, currentCategoryOptions) => {
    const selectedCatLenght = countSelectedCategories();
    if (level < selectedCatLenght) {
      for (let i = selectedCatLenght; i > level; i--) {
        formApi.change(`${prefix}${i}`, null);
      }
    }
    const categoryConfig = findCategoryConfig(currentCategoryOptions, category).subcategories;
    setAllCategoriesChosen(!categoryConfig || categoryConfig.length === 0);
  };

  return (
    <CategoryField
      currentCategoryOptions={listingCategories}
      level={1}
      values={values}
      prefix={prefix}
      handleCategoryChange={handleCategoryChange}
      intl={intl}
    />
  );
};

// Add collect data for listing fields (both publicData and privateData) based on configuration
const AddListingFields = props => {
  const { listingType, listingFieldsConfig, selectedCategories, formId, intl } = props;
  const targetCategoryIds = Object.values(selectedCategories);

  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, schemaType, scope } = fieldConfig || {};
    const namespacedKey = scope === 'public' ? `pub_${key}` : `priv_${key}`;

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isProviderScope = ['public', 'private'].includes(scope);
    const isTargetListingType = isFieldForListingType(listingType, fieldConfig);
    const isTargetCategory = isFieldForCategory(targetCategoryIds, fieldConfig);

    return isKnownSchemaType && isProviderScope && isTargetListingType && isTargetCategory
      ? [
        ...pickedFields,
        <CustomExtendedDataField
          key={namespacedKey}
          name={namespacedKey}
          fieldConfig={fieldConfig}
          defaultRequiredMessage={intl.formatMessage({
            id: 'EditListingDetailsForm.defaultRequiredMessage',
          })}
          formId={formId}
        />,
      ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

// Form that asks title, description, transaction process and unit type for pricing
// In addition, it asks about custom fields according to marketplace-custom-config.js
const EditListingDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        form,
        form: {
          mutators: { push }
        },
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        currentUser,
      } = formRenderProps;

      const { listingType, transactionProcessAlias, unitType } = values;
      const [allCategoriesChosen, setAllCategoriesChosen] = useState(false);

      // const titleRequiredMessage = intl.formatMessage({
      //   id: 'EditListingDetailsForm.titleRequired',
      // });

      const usernameRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.usernameRequired',
      });

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );


      const maxDescriptionLength = getDescriptionWordsLimit(currentUser);

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxPhotosLimit = getUserPhotosLimit(currentUser);

      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const showTitle = hasCategories ? allCategoriesChosen : listingType;
      const showDescription = hasCategories ? allCategoriesChosen : listingType;
      const showListingFields = hasCategories ? allCategoriesChosen : listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            formId={formId}
            intl={intl}
          />

          {showCategories ? (
            <FieldSelectCategory
              values={values}
              prefix={categoryPrefix}
              listingCategories={selectableCategories}
              formApi={formApi}
              intl={intl}
              allCategoriesChosen={allCategoriesChosen}
              setAllCategoriesChosen={setAllCategoriesChosen}
            />
          ) : null}

          <div className={css.imagesContainer}>
            {/* <FieldAzureImageUpload
              label={intl.formatMessage({ id: "EditListingDetailsForm.addProfilePicture" })}
              id='profilePicture'
              name='profilePicture'
              isRounded={true}
            /> */}



            <FieldArray name="profilePictures">
              {({ fields }) => fields.map((name, index) => {
                const value = values.profilePictures[index]
                const handleDelete = () => {
                  fields.remove(index);
                }

                return (
                  <div className={css.imageContainer} key={index}>
                    <FieldAzureImageUpload
                      id={`${name}profilePicture`}
                      name={`${name}profilePicture`}
                      label={intl.formatMessage({ id: "EditListingDetailsForm.addProfilePicture" })}
                      isRounded={true}
                      onImageRemove={handleDelete}
                      imageLimitReached={(values.profilePictures.length - 1) === maxPhotosLimit}
                      onUploadComplete={() => {
                        push('profilePictures', '');
                      }}
                    />
                  </div>
                )
              })}
            </FieldArray>
          </div>

          <p className={css.photoTip}>
            <strong>
              <FormattedMessage id="MaxPhotosLimit" values={{ value: maxPhotosLimit }} />
            </strong>
          </p>

          <p className={css.photoTip}>
            <strong>
              <FormattedMessage id="EditListingDetailsForm.photoTipLabel" />
            </strong>
            <FormattedMessage id="EditListingDetailsForm.photoTip" values={{ lineBreak: <br /> }} />
          </p>
          <div>
            <FieldTextInput
              id={`${formId}description`}
              name="description"
              className={css.description}
              type="textarea"
              // maxLength={maxDescriptionLength}
              // label={intl.formatMessage({ id: 'EditListingDetailsForm.description' })}
              label={intl.formatMessage({ id: 'EditListingDetailsForm.aboutYou' }, { value: maxDescriptionLength })}
              // placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.descriptionPlaceholder' })}
              placeholder={intl.formatMessage({ id: 'EditListingDetailsForm.aboutYouPlaceholder' })}
              // validate={required(intl.formatMessage({ id: 'EditListingDetailsForm.descriptionRequired' }))}
              validate={
                composeValidators(
                  required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.aboutYouRequired' })),
                  maxWords(
                    intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.maxWords' },
                      {
                        maxLength: maxDescriptionLength,
                      }
                    ), maxDescriptionLength
                  )
                )
              }
            />
            <p className={css.charactersCount}>
              {wordsCount(values.description) + "/" + maxDescriptionLength}
            </p>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form >
      );
    }}
  />
);

EditListingDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingDetailsFormComponent);
