import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import configs and util modules
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';

// Import shared components
import {
  Button,
  FieldMultipleItems,
  FieldSingleSelect,
  Form
} from '../../../../components';

// Import modules from this directory
import css from './EditListingHouseSitterPropertyExperienceForm.module.css';
import { propertyExperienceTypes } from '../../../../util/propertyExperienceTypes';
import { availableForCommunication } from '../../../../util/availableForCommunication';

export const EditListingHouseSitterPropertyExperienceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const availableForCommunicationLabel = <div><label >
        <FormattedMessage id={"EditListingHouseSitterPropertyExperienceForm.availableForCommunication"} />
      </label>
        <label className={css.info}>
          <FormattedMessage id={"EditListingHouseSitterPropertyExperienceForm.availableForCommunicationInfo"} />
        </label></div>

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterPropertyExperienceForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingHouseSitterPropertyExperienceForm.showListingFailed" />
            </p>
          ) : null}


          <FieldMultipleItems
            className={css.fieldClass}
            name="propertyMaintananceExperience"
            id={`${formId}propertyMaintananceExperience`}
            label={intl.formatMessage({ id: "EditListingHouseSitterPropertyExperienceForm.propertyMaintananceExperience" })}
            options={propertyExperienceTypes}
          />

          <div>

            <FieldSingleSelect
              className={css.fieldClass}
              name="availableForCommunication"
              id={`${formId}availableForCommunication`}
              label={availableForCommunicationLabel}
              options={availableForCommunication}
            />

          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingHouseSitterPropertyExperienceFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'EditListingHouseSitterPropertyExperienceForm',
};

EditListingHouseSitterPropertyExperienceFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingHouseSitterPropertyExperienceFormComponent);
