import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { storableError } from '../../util/errors';
import { listingTypes } from '../../util/listingHelpers';
import { createImageVariantConfig } from '../../util/sdkLoader';
export const ASSET_NAME = 'landing-page';

const getImageVariantInfo = listingImageConfig => {
  const { aspectRatio, variantPrefix = 'listing-card' } = listingImageConfig;
  // const aspectRatio = aspectHeight / aspectWidth;
  const fieldsImage = [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`, `variants.${variantPrefix}-4x`,];

  return {
    fieldsImage,
    imageVariants: {
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    },
  };
};

// ================ Action types ================ //


export const FETCH_LISTINGS_REQUEST = 'app/LandingPage/QUERY_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/LandingPage/QUERY_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/LandingPage/QUERY_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listings: [],
  queryInProgress: false,
  queryListingsError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        listings: [],
        queryInProgress: true,
        queryListingsError: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: payload.data || [],
        queryInProgress: false,
        queryListingsError: null,

      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        queryListingsError: payload
      };

    default:
      return state;
  }
};

// ================ Action creators ================ //


export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const queryListings = (config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  const { aspectWidth = 4, aspectHeight = 3, variantPrefix = 'listing-card' } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const queryParams = {
    pub_listingType: listingTypes.houseSitBooking,
    include: ['author', 'images', 'author.profileImage'],
    per_page: 3,
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  };

  return sdk.listings
    .query({ ...queryParams })
    .then(response => {
      const listings = response.data?.data;

      const listingsWithImages = listings.map(l => {

        const listingImages = l.relationships.images.data;
        const includedImages = response.data.included.filter(i => listingImages.some(li => li.id.uuid === i.id.uuid));

        if (includedImages && includedImages.length > 0) {
          l.images = includedImages;
        }

        const authorData = l.relationships.author.data;
        const includedAuthor = response.data.included.filter(a => authorData.id.uuid === a.id.uuid);

        l.author = includedAuthor[0];
        return l;
      })
      dispatch(queryListingsSuccess(listingsWithImages));
      return listingsWithImages;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

export default landingPageReducer;

export const loadData = (params, search, config) => dispatch => {
  // const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  // return dispatch(fetchPageAssets(pageAsset, true));
  return dispatch(queryListings(config));

  // return Promise.all([
  //   dispatch(fetchPageAssets(pageAsset, true)),
  //   dispatch(queryListings(config)),
  // ])
};
