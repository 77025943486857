import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {
  H2,
  LayoutSideNavigation,
  Page,
  Button
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import css from './StripeSubscriptions.module.css';
import { post } from '../../util/api';
import moment from 'moment';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const Cancel = ({ location, currentUser }) => {
  const [cancelled, setCancelled] = useState(false);
  const [updatedPrivateData, setUpdatedPrivateData] = useState(false);
  const title = 'Subscription';
  const priceId = location.state.priceId;
  const handleClick = async e => {
    e.preventDefault();

    return post('/api/cancel-subscription', {
      "subscriptionId": location.state.subscription,
    })
      .then(resp => {
        setCancelled(true);

        return sdk.currentUser
          .show()
          .then(res => {
            const subscriptions = res.data.data.attributes.profile.privateData.subscriptions;
            const updatedSubscription = {
              ...subscriptions[0],
              endDate: moment().utc().toISOString()
            };

            // for (var i = 0; i < updatedSubscription.length; i++) {
            //TODO check why here priceId is undefined. 
            // anyway, we shouldn't allow more than one subscription! 
            // TODO
            //if (subscriptionsCopy[i] === priceId) {
            // updatedSubscription.splice(i, 1);
            //}
            // }

            return sdk.currentUser
              .updateProfile({
                privateData: {
                  subscriptions: [updatedSubscription],
                },
              })
              .then(resp => {
                setUpdatedPrivateData(true);
              })
              .catch(e => console.log(e));
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));
  };

  if (cancelled && updatedPrivateData) {
    return <Redirect to={`/account/subscriptions`} />;
  }

  return (
    <Page title={title}>
      <LayoutSideNavigation
        sideNavClassName={css.navigation}
        topbar={
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="StripeAccountSubscriptionsPage"
          />
        }
        sideNav={
          <>
            <H2 as="h1" className={css.title}>
              Subscriptions
            </H2>
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <center>
            <h2>Are you sure you want to cancel this subscription?</h2>
          </center>
          <br />
          <Button onClick={handleClick}>Cancel</Button>
        </div>
      </LayoutSideNavigation>
    </Page>
  );
};

export default withRouter(Cancel);