import { types as sdkTypes } from './sdkLoader';
const { LatLng, Money } = sdkTypes;

export const getIsHouseOwnerListing = (listing) => {
    if (!listing) return null;

    return listing.attributes.publicData.listingType === listingTypes.houseSitBooking;
}

export const getIsHouseListing = (listing) => {
    if (!listing) return null;

    return listing.attributes.publicData.listingType === listingTypes.house;
}

export const getIsHouseSitterListing = (listing) => {
    if (!listing) return null;

    return listing.attributes.publicData.listingType === listingTypes.houseSitter;
}

export const kindTypes = {
    houseSitter: "house-sitter",
    houseSitBooking: "house-sit-booking",
    house: "house",
    none: "none",
}

export const listingTypes = {
    houseSitter: "house-sitter",
    houseSitBooking: "house-sit-booking",
    house: "house",
    none: "none",
}


export const getKind = (listing) => {
    if (!listing) return null;

    if (listing.attributes.publicData.listingType === 'house')
        return kindTypes.house;

    if (listing.attributes.publicData.listingType === 'house-sit-booking')
        return kindTypes.houseSitBooking;


    if (listing.attributes.publicData.listingType === 'house-sitter')
        return kindTypes.houseSitter;

    return kindTypes.none;
}

export const getHouseSitListingValues = (houseListing, houseSitListingValues, imageIds) => {

    const newListing = {};

    newListing.title = houseSitListingValues.title;
    newListing.description = houseListing.attributes.description;
    newListing.publicData = { ...houseListing.attributes.publicData, ...houseSitListingValues.publicData };
    newListing.privateData = { ...houseListing.attributes.privateData, ...houseSitListingValues.privateData };
    newListing.geolocation = new LatLng(houseListing.attributes.geolocation.lat, houseListing.attributes.geolocation.lng);
    newListing.price = new Money(houseListing.attributes.price.amount, houseListing.attributes.price.currency);
    newListing.images = imageIds;

    return newListing;
}
