import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import CatIcon from './images/cat-icon.svg';
import DogIcon from './images/dog-icon.svg';
import ReptileIcon from './images/turtle-icon.svg';
import HorseIcon from './images/horse-icon.svg';
import BirdIcon from './images/bird-icon.svg';
import FishIcon from './images/fish-icon.svg';
import PoultryIcon from './images/duck-icon.svg';
import LivestockIcon from './images/livestock-icon.svg';
// import SmallPetIcon from './images/small-pet-icon.svg';
import NoImage from './images/noImageIcon.svg'

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';
import moment from 'moment';
import { getIsHouseListing, getIsHouseOwnerListing, getIsHouseSitterListing } from '../../util/listingHelpers';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

export const getPetIcon = (petKey) => {
  const iconMap = {
    catsList: CatIcon,
    dogsList: DogIcon,
    horsesList: HorseIcon,
    birdsList: BirdIcon,
    fishesList: FishIcon,
    livestockList: LivestockIcon,
    poultryList: PoultryIcon,
    reptilesList: ReptileIcon,
    smallPetsList: NoImage,
    // smallPetsList: SmallPetIcon,
  };

  return iconMap[petKey] || null;
};

// const priceData = (price, currency, intl) => {
//   if (price && price.currency === currency) {
//     const formattedPrice = formatMoney(intl, price);
//     return { formattedPrice, priceTitle: formattedPrice };
//   } else if (price) {
//     return {
//       formattedPrice: intl.formatMessage(
//         { id: 'ListingCard.unsupportedPrice' },
//         { currency: price.currency }
//       ),
//       priceTitle: intl.formatMessage(
//         { id: 'ListingCard.unsupportedPriceTitle' },
//         { currency: price.currency }
//       ),
//     };
//   }
//   return {};
// };

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

// const PriceMaybe = props => {
//   const { price, publicData, config, intl } = props;
//   const { listingType } = publicData || {};
//   const validListingTypes = config.listing.listingTypes;
//   const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
//   const showPrice = displayPrice(foundListingTypeConfig);
//   if (!showPrice && price) {
//     return null;
//   }

//   const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
//   const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
//   return (
//     <div className={css.price}>
//       <div className={css.priceValue} title={priceTitle}>
//         {formattedPrice}
//       </div>
//       {isBookable ? (
//         <div className={css.perUnit}>
//           <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
//         </div>
//       ) : null}
//     </div>
//   );
// };

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { availabilityInterval, pets = '', locationPublic, propertyDescription, jobTitle } = publicData;

  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isHouseSitterListing = getIsHouseSitterListing(currentListing);

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = isHouseSitterListing
      ? config.layout.listingImageProfile
      : config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const isHomeOwnerListing = getIsHouseOwnerListing(currentListing);
  const isHouseListing = getIsHouseListing(currentListing);

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>


      {isHomeOwnerListing ?
        <div className={css.info}>
          {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
            {availabilityInterval &&
              <p className={css.interval}>
                {`${moment(availabilityInterval.start).format('D MMM')} - ${moment(availabilityInterval.end).format('D MMM YYYY')}`}
              </p>
            }

            {locationPublic &&
              <p className={css.address}>
                {locationPublic?.city}, {locationPublic?.country}
              </p>
            }

            <div className='row gap20'>
              {Object.entries(pets).map(([key, animalList]) => (
                animalList.length !== 0 &&
                <div key={key} className='row gap5'>
                  <p className={css.interval}>
                    {animalList.length}
                  </p>
                  <img src={getPetIcon(key)} alt={`${key} icon`} className={css.petIcon} />
                </div>

              ))}
            </div>
          </div>
        </div>
        :
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {isHouseListing && propertyDescription &&
              <p className={css.interval}>
                {propertyDescription}
              </p>
            }

            <p className={css.address}>
              {jobTitle}
            </p>
          </div>
        </div>
      }
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,
  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
