import { post } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const convertDateToStripeApiFormat = date => Math.round(new Date(date).getTime() / 1000);

export const updateSubscriptionStartDateMaybe = (subscriptionId, priceId) => {
  if (!subscriptionId) {
    return Promise.resolve('missing info or subscription starts now');
  } else {
    return sdk.currentUser
      .show()
      .then(resp => {
        const trialPeriod =
          resp?.data?.data?.attributes?.profile?.protectedData?.trialPeriod || 'noTrial';

        const trialPeriodToDate = trialPeriod => {
          const today = new Date();
          let date;
          switch (trialPeriod) {
            case 'noTrial':
              date = null;
              break;
            case 'oneMonth':
              date = new Date(today.setMonth(today.getMonth() + 1));
              break;
            case 'twoMonths':
              date = new Date(today.setMonth(today.getMonth() + 2));
              break;
            case 'threeMonths':
              date = new Date(today.setMonth(today.getMonth() + 3));
              break;
            case 'fourMonths':
              date = new Date(today.setMonth(today.getMonth() + 4));
              break;
            default:
              date = today;
          }
          return date;
        };

        const trialPeriodDate = trialPeriodToDate(trialPeriod);

        const newSubscriptionStartDate = trialPeriodDate
          ? { trial_end: convertDateToStripeApiFormat(trialPeriodDate) }
          : {};

        return post('/api/update-subscription', {
          subscriptionId,
          priceId,
          updateData: {
            ...newSubscriptionStartDate,
            proration_behavior: 'none',
          },
        }).catch(e => {
          console.log(e);
          return 'error on update subscription';
        });
      })
      .catch(e => {
        console.log(e);
        return 'error on update subscription';
      });
  }
};
