import React, { useEffect, useRef, useState } from "react";
import arrow from './arrow.png';
import css from './CollapseFAQ.module.css';

const CollapseFAQ = ({ list: faqList }) => {

    const [activeItem, setActiveItem] = useState(null);
    const answerRefs = useRef([]);

    const toggleItem = index => {
        setActiveItem(activeItem === index ? null : index);
    };

    useEffect(() => {
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                if (activeItem === index) {
                    ref.style.maxHeight = `${ref.scrollHeight}px`;
                } else {
                    ref.style.maxHeight = '0';
                }
            }
        });
    }, [activeItem]);

    return (
        <ul className={css.root}>
            {faqList.map((item, questionIndex) => (
                <li
                    key={`question${questionIndex}`}
                    className={`${css.item} ${activeItem === questionIndex ? css.active : ''}`}
                >
                    <p
                        className={css.question}
                        onClick={() => toggleItem(questionIndex)}
                    >
                        <strong>{item.question}</strong>
                        <img src={arrow} alt="Arrow" className={activeItem === questionIndex ? css.arrowRotated : css.arrow} />
                    </p>
                    <div
                        className={css.answer}
                        ref={el => (answerRefs.current[questionIndex] = el)}
                    >
                        {item.answer}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default CollapseFAQ;