import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { EXTENDED_DATA_SCHEMA_TYPES, propTypes } from '../../../../util/types';
import { isFieldForCategory, isFieldForListingType } from '../../../../util/fieldHelpers';
import { maxLength, required, composeValidators, maxWords } from '../../../../util/validators';

// Import shared components
import {
  Form,
  Button,
  FieldSelect,
  FieldTextInput,
  Heading,
  CustomExtendedDataField,
} from '../../../../components';
import css from './EditListingHouseSitterDetailsForm.module.css';
import { getDescriptionWordsLimit, getUserPhotosLimit } from '../../../../util/subscriptionsHelpers';
import { genderTypes } from '../../../../util/genderTypes';
import { ageTypes } from '../../../../util/ageTypes';
import { FieldArray } from 'react-final-form-arrays';
import { wordsCount } from '../../../../util/wordsHelpers';
import FieldInternationalPhoneNumberInput from '../../../../components/FieldInternationalPhoneNumberInput/FieldInternationalPhoneNumberInput';
import { houseSitterStatuses } from '../../../../util/housesitterStatuses';

const TITLE_MAX_LENGTH = 60;

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="EditListingHouseSitterDetailsForm.updateFailed" />
  ) : createListingDraftError ? (
    <FormattedMessage id="EditListingHouseSitterDetailsForm.createListingDraftError" />
  ) : showListingsError ? (
    <FormattedMessage id="EditListingHouseSitterDetailsForm.showListingFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  }
  return null;
};

// Hidden input field
const FieldHidden = props => {
  const { name } = props;
  return (
    <Field id={name} name={name} type="hidden" className={css.unitTypeHidden}>
      {fieldRenderProps => <input {...fieldRenderProps?.input} />}
    </Field>
  );
};

const FieldSelectListingType = props => {
  const {
    name,
  } = props;

  return (
    <>
      <FieldHidden name={name} />
      <FieldHidden name="transactionProcessAlias" />
      <FieldHidden name="unitType" />
    </>
  );
};

const EditListingHouseSitterDetailsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        formId,
        form: formApi,
        form,
        form: {
          mutators: { push }
        },
        handleSubmit,
        onListingTypeChange,
        intl,
        invalid,
        pristine,
        selectableListingTypes,
        selectableCategories,
        hasExistingListingType,
        pickSelectedCategories,
        categoryPrefix,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        currentUser,
      } = formRenderProps;

      const { listingType, transactionProcessAlias, unitType } = values;

      // const titleRequiredMessage = intl.formatMessage({
      //   id: 'EditListingHouseSitterDetailsForm.titleRequired',
      // });

      const usernameRequiredMessage = intl.formatMessage({
        id: 'EditListingHouseSitterDetailsForm.usernameRequired',
      });

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingHouseSitterDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );


      const maxDescriptionLength = getDescriptionWordsLimit(currentUser);

      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxPhotosLimit = getUserPhotosLimit(currentUser);

      const hasCategories = selectableCategories && selectableCategories.length > 0;
      const showCategories = listingType && hasCategories;

      const showTitle = hasCategories ? allCategoriesChosen : listingType;
      const showDescription = hasCategories ? allCategoriesChosen : listingType;
      const showListingFields = hasCategories ? allCategoriesChosen : listingType;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const hasMandatoryListingTypeData = listingType && transactionProcessAlias && unitType;
      const submitDisabled =
        invalid || disabled || submitInProgress || !hasMandatoryListingTypeData;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <FieldSelectListingType
            name="listingType"
            listingTypes={selectableListingTypes}
            hasExistingListingType={hasExistingListingType}
            onListingTypeChange={onListingTypeChange}
            formApi={formApi}
            formId={formId}
            intl={intl}
          />

          <FieldTextInput
            id={`${formId}title`}
            name="title"
            className={css.title}
            type="text"
            label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.username' })}
            placeholder={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.usernamePlaceholder' })}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(usernameRequiredMessage), maxLength60Message)}
            autoFocus={autoFocus}
          />

          <FieldTextInput
            id={`${formId}jobTitle`}
            name="jobTitle"
            className={css.title}
            type="text"
            label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.jobTitle' })}
            placeholder={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.jobTitlePlaceholder' })}
          />

          <div>
            <FieldTextInput
              id={`${formId}description`}
              name="description"
              className={css.description}
              type="textarea"
              // maxLength={maxDescriptionLength}
              label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.aboutYou' }, { value: maxDescriptionLength })}
              placeholder={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.aboutYouPlaceholder' })}
              validate={
                composeValidators(
                  required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.aboutYouRequired' })),
                  maxWords(
                    intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.maxWords' },
                      {
                        maxLength: maxDescriptionLength,
                      }
                    ), maxDescriptionLength
                  )
                )
              }
            />
            <p className={css.charactersCount}>
              {wordsCount(values.description) + "/" + maxDescriptionLength}
            </p>
          </div>

          <div className='row gap20'>
            <FieldSelect
              id={`${formId}.gender`}
              className='halfWidth'
              name="gender"
              label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.gender' })}
              validate={required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.genderRequired' }))}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.genderPlaceholder' })}
              </option>
              {genderTypes.map(o => (
                <option value={o.option}>
                  {o.label}
                </option>
              ))}
            </FieldSelect>

            <FieldSelect
              id={`${formId}.age`}
              className='halfWidth'
              name="age"
              label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.age' })}
              validate={required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.ageRequired' }))}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.agePlaceholder' })}
              </option>
              {ageTypes.map(o => (
                <option value={o.option}>
                  {o.label}
                </option>
              ))}
            </FieldSelect>
          </div>

          <div className='row gap20 mt40'>
            <FieldSelect
              id={`${formId}.status`}
              className='halfWidth'
              name="status"
              label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.status' })}
              validate={required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.statusRequired' }))}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.statusPlaceholder' })}
              </option>
              {houseSitterStatuses.map(o => (
                <option value={o.option}>
                  {o.label}
                </option>
              ))}
            </FieldSelect>

            {values.status === "family" &&
              <FieldSelect
                id={`${formId}.familyCount`}
                className='halfWidth'
                name="familyCount"
                label={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.familyCount' })}
                validate={required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.familyCountRequired' }))}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.familyCountPlaceholder' })}
                </option>
                <option value={0}>
                  {0}
                </option>
                {Array.from({ length: 20 }, (_, index) => index + 1).map(o => (
                  <option value={o}>
                    {o}
                  </option>
                ))}
              </FieldSelect>
            }
          </div>


          <div className='col mt30'>
            <label>
              <FormattedMessage id="EditListingHouseSitterDetailsForm.emergencyContacts" />
            </label>
            <label className='info'>
              <FormattedMessage id="EditListingHouseSitterDetailsForm.emergencyContactsInfo" />
            </label>

            <FieldArray name="emergencyContacts">
              {({ fields }) => fields.map((name, index) => {
                const value = values.emergencyContacts[index]
                const handleDelete = () => {
                  fields.remove(index);
                }

                return (
                  <div className='col gap20 center width-full'>

                    <div className='row gap20'>

                      <FieldTextInput
                        id={`${name}name`}
                        name={`${name}name`}
                        label={index === 0 ? intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.name' }) : ""}
                        placeholder={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.namePlaceholder' })}
                      // validate={required(intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.nameRequired' },)
                      // )}
                      />

                      <FieldInternationalPhoneNumberInput
                        type="text"
                        id={`${name}phoneNumber`}
                        name={`${name}phoneNumber`}
                        labelClasName={css.labelPhone}
                        label={index === 0 ? intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.phoneNumber' }) : ""}
                        placeholder={intl.formatMessage({ id: 'EditListingHouseSitterDetailsForm.phoneNumberPlaceholder' })}
                        defaultCountry="GB"
                        withCountryCallingCode
                        countryCallingCodeEditable={false}
                        international />

                      {values.emergencyContacts && values.emergencyContacts[0] != '' &&
                        <div className='row' onClick={handleDelete}>
                          <p className={index === 0 ? css.deleteButtonFirst : css.deleteButton} >
                            <FormattedMessage id="EditListingHouseSitterDetailsForm.delete" />
                          </p>
                        </div>
                      }

                    </div>

                    {/* <div className='line mb10' /> */}
                  </div>
                )
              })}
            </FieldArray>


            <div className='row' onClick={(e) => {
              e.preventDefault();
              push('emergencyContacts', '');
            }}>
              <p className={css.addButton} >
                <FormattedMessage id="EditListingHouseSitterDetailsForm.addContact" />
              </p>
            </div>

          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form >
      );
    }}
  />
);

EditListingHouseSitterDetailsFormComponent.defaultProps = {
  className: null,
  formId: 'EditListingHouseSitterDetailsForm',
  fetchErrors: null,
  hasExistingListingType: false,
  listingFieldsConfig: [],
};

EditListingHouseSitterDetailsFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  onListingTypeChange: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  pickSelectedCategories: func.isRequired,
  selectableListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionProcessAlias: string.isRequired,
      unitType: string.isRequired,
    })
  ).isRequired,
  hasExistingListingType: bool,
  listingFieldsConfig: propTypes.listingFields,
};

export default compose(injectIntl)(EditListingHouseSitterDetailsFormComponent);
