import React, { useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { ARRAY_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import Cropper from "react-easy-crop";


// Import configs and util modules
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import getCroppedImg from '../../../../util/getCroppedImg';
import { nonEmptyArray, composeValidators } from '../../../../util/validators';
import { isUploadImageOverLimitError } from '../../../../util/errors';

// Import shared components
import { Button, Form, AspectRatioWrapper, Modal, PrimaryButton } from '../../../../components';

// Import modules from this directory
import ListingImage from './ListingImage';
import css from './EditListingHouseSitterPhotosForm.module.css';
import { getUserPhotosLimit } from '../../../../util/subscriptionsHelpers';

const ACCEPT_IMAGES = 'image/*';
const SIZE_LIMIT = 20;

const ImageUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingHouseSitterPhotosForm.imageUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadImageError ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingHouseSitterPhotosForm.imageUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

// NOTE: PublishListingError and ShowListingsError are here since Photos panel is the last visible panel
// before creating a new listing. If that order is changed, these should be changed too.
// Create and show listing errors are shown above submit button
const PublishListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingHouseSitterPhotosForm.publishListingFailed" />
    </p>
  ) : null;
};

const ShowListingsError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingHouseSitterPhotosForm.showListingFailed" />
    </p>
  ) : null;
};

// Field component that uses file-input to allow user to select images.
// export const FieldAddImage = props => {
//   const {
//     formApi,
//     onImageUploadHandler,
//     aspectWidth = 1,
//     aspectHeight = 1,
//     intl,
//     ...rest } = props;

//   const [errorContent, setErrorContent] = useState();

//   return (
//     <Field form={null} {...rest}>
//       {fieldprops => {
//         const { accept, input, label, disabled: fieldDisabled } = fieldprops;
//         const { name, type } = input;

//         const onChange = e => {
//           const file = e.target.files[0];

//           if (!file || !file.type.startsWith("image/")) {
//             setErrorContent(
//               <span style={{ width: '70%', textAlign: 'center' }}>
//                 {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileType" })}
//               </span>
//             );
//             return;
//           }

//           if (file.size > SIZE_LIMIT * 1024 * 1024) {
//             setErrorContent(
//               <span style={{ width: '70%', textAlign: 'center' }}>
//                 {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileSize" })}
//               </span>
//             );
//             return;
//           }

//           formApi.change(`addImage`, file);
//           formApi.blur(`addImage`);
//           onImageUploadHandler(file);
//           setErrorContent();
//         };
//         const inputProps = { accept, id: name, name, onChange, type };
//         return (
//           <div className={css.addImageWrapper}>
//             <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
//               {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
//               <label htmlFor={name} className={classNames(css.addImage, { [css.error]: errorContent })}>
//                 {errorContent || label}
//               </label>
//             </AspectRatioWrapper>
//           </div>
//         );
//       }
//       }
//     </Field>
//   );
// };

export const FieldAddImage = (props) => {
  const {
    formApi,
    onImageUploadHandler,
    aspectWidth = 1,
    aspectHeight = 1,
    intl,
    onManageDisableScrolling,
    ...rest
  } = props;

  const [errorContent, setErrorContent] = useState();
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageCropModal, setImageCropModal] = useState(false);

  const onCropComplete = (croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const onChange = (e) => {
    const file = e.target.files[0];

    if (!file || !file.type.startsWith("image/")) {
      setErrorContent(
        <span style={{ width: "70%", textAlign: "center" }}>
          {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileType" })}
        </span>
      );
      return;
    }

    if (file.size > SIZE_LIMIT * 1024 * 1024) {
      setErrorContent(
        <span style={{ width: "70%", textAlign: "center" }}>
          {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileSize" })}
        </span>
      );
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageCropModal(true)
      setImageSrc(reader.result);
    };

    setErrorContent();
  };

  const handleCropConfirm = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    try {
      const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedFile = new File([croppedImageBlob], `cropped_${Date.now()}.jpg`, {
        type: "image/jpeg",
      });

      onImageUploadHandler(croppedFile);
      setImageSrc(null);
      setImageCropModal(false);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <Field form={null} {...rest}>
      {(fieldprops) => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;

        const inputProps = { accept, id: name, name, onChange, type };

        return (
          <>
            <div className={css.addImageWrapper}>
              <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
                {fieldDisabled ? null : (
                  <input {...inputProps} className={css.addImageInput} />
                )}
                <label
                  htmlFor={name}
                  className={classNames(css.addImage, { [css.error]: errorContent })}
                >
                  {errorContent || label}
                </label>
              </AspectRatioWrapper>

            </div>
            <Modal
              id={"ImageCropModal"}
              containerClassName={css.containerClassName}
              contentClassName={css.modalContent}
              isOpen={imageCropModal}
              onClose={() => {
                setImageCropModal(false)
                setImageSrc(null)
              }}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
              closeButtonMessage={intl.formatMessage({ id: 'VideoMeetingRequestModal.close' })}
            >
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={aspectWidth / aspectHeight}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              <PrimaryButton onClick={handleCropConfirm} className={css.cropButton}>
                Crop & Upload
              </PrimaryButton>
            </Modal>
          </>
        );
      }}
    </Field>
  );
};


// Component that shows listing images from "images" field array
const FieldListingImage = props => {
  const { name, intl, onRemoveImage, aspectWidth, aspectHeight, variantPrefix } = props;

  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const image = input.value;
        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingHouseSitterPhotosForm.savedImageAltText',
            })}
            onRemoveImage={() => onRemoveImage(image?.id)}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            variantPrefix={variantPrefix}
          />
        ) : null;
      }}
    </Field>
  );
};

export const EditListingHouseSitterPhotosFormComponent = props => {
  const [state, setState] = useState({ imageUploadRequested: false });
  const [submittedImages, setSubmittedImages] = useState([]);

  const onImageUploadHandler = (file) => {
    const {
      listingImageConfig,
      onImageUpload,
      onUpdateProfileImage,
      config,
    } = props;

    if (file) {
      setState({ imageUploadRequested: true });

      onImageUpload({ id: `${file.name}_${Date.now()}`, file }, listingImageConfig)
        .then(() => {
          setState({ imageUploadRequested: false });

          onUpdateProfileImage({
            file: file,
          }, config)

        })
        .catch(() => {
          setState({ imageUploadRequested: false });
        });
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          form,
          formId,
          className,
          fetchErrors,
          handleSubmit,
          intl,
          invalid,
          onRemoveImage,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          touched,
          errors,
          values,
          listingImageConfig,
          listingFieldsConfig,
          currentUser,
          config,
          onUpdateProfileImage,
          onManageDisableScrolling,
        } = formRenderProps;

        const images = values.images;
        const { aspectWidth = 1, aspectHeight = 1, variantPrefix } = listingImageConfig;

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const submittedOnce = submittedImages.length > 0;
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || state.imageUploadRequested || ready;
        const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];

        const classes = classNames(css.root, className);

        const maxPhotosLimit = getUserPhotosLimit(currentUser);
        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              handleSubmit(e);
            }}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingHouseSitterPhotosForm.updateFailed" />
              </p>
            ) : null}

            <div className={css.imagesFieldArray}>
              <FieldArray
                name="images"
                validate={composeValidators(
                  nonEmptyArray(
                    intl.formatMessage({
                      id: 'EditListingHouseSitterPhotosForm.imageRequired',
                    })
                  )
                )}
              >
                {({ fields }) =>
                  fields.map((name, index) => (
                    <FieldListingImage
                      key={name}
                      name={name}
                      onRemoveImage={imageId => {
                        fields.remove(index);
                        onRemoveImage(imageId);
                      }}
                      intl={intl}
                      aspectWidth={aspectWidth}
                      aspectHeight={aspectHeight}
                      variantPrefix={variantPrefix}
                    />
                  ))
                }
              </FieldArray>

              {values.images.length < maxPhotosLimit &&
                <FieldAddImage
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  label={
                    <span className={css.chooseImageText}>
                      <span className={css.chooseImage}>
                        <FormattedMessage id="EditListingHouseSitterPhotosForm.chooseImage" />
                      </span>
                      <span className={css.imageTypes}>
                        <FormattedMessage id="EditListingHouseSitterPhotosForm.imageTypes" />
                      </span>
                    </span>
                  }
                  type="file"
                  disabled={state.imageUploadRequested}
                  formApi={form}
                  onImageUploadHandler={onImageUploadHandler}
                  aspectWidth={aspectWidth}
                  aspectHeight={aspectHeight}
                  intl={intl}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              }
            </div>

            {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}

            <p className={css.photoTip}>
              <strong>
                <FormattedMessage id="MaxPhotosLimit" values={{ value: maxPhotosLimit }} />
              </strong>
            </p>

            <p className={css.photoTip}>
              <strong>
                <FormattedMessage id="EditListingDetailsForm.photoTipLabel" />
              </strong>
              <FormattedMessage id="EditListingDetailsForm.photoTip" values={{ lineBreak: <br /> }} />
            </p>


            <ImageUploadError
              uploadOverLimit={uploadOverLimit}
              uploadImageError={uploadImageError}
            />

            <PublishListingError error={publishListingError} />
            <ShowListingsError error={showListingsError} />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingHouseSitterPhotosFormComponent.defaultProps = { fetchErrors: null };

EditListingHouseSitterPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default compose(injectIntl)(EditListingHouseSitterPhotosFormComponent);
