import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
  LayoutSideNavigation,
  Page,
  Button,
  H2,
  IconSpinner
} from '../../components';
import Overlay from '../ManageListingsPage/ManageListingCard/Overlay';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import css from './StripeSubscriptions.module.css';
import { updateSubscriptionStartDateMaybe } from './utils';
import { getPurchasedSubscriptionDetails } from '../../util/subscriptionsHelpers';
import { post } from '../../util/api';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { updateUserSubscriptionProfile } from './SubscribePage.duck';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const ShowIsBusy = ({ isBusy }) => {
  return isBusy ? (
    <Overlay
      message={
        <div>
          <IconSpinner />
          <br />
          <br />
          Purchasing
        </div>
      }
    >

    </Overlay>
  ) : null;
};

const SubscribeComponent = props => {
  const {
    scrollingDisabled,
    location,
    currentUser,
    onUpdateUserSubscriptionProfile
  } = props;

  const title = 'Subscription';
  const [clientSecret] = useState(location.state.clientSecret);
  const [subscriptionId] = useState(location.state.subscriptionId);
  const [priceId] = useState(location.state.priceId);
  const [name, setName] = useState(location.state.userName);
  const [userPrivateData, setUserPrivateData] = useState();
  const [messages, _setMessages] = useState('');
  const [paymentIntent, setPaymentIntent] = useState();
  const [updatedPrivateData, setUpdatedPrivateData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState(currentUser?.attributes.profile.privateData.subscriptions || []);

  useEffect(() => {
    sdk.currentUser
      .show({
        include: ['stripeAccount', 'stripeCustomer'],
      })
      .then(resp => {
        const privateData = resp.data.data.attributes.profile.privateData;
        setUserPrivateData(privateData);
        setSubscriptions(privateData.subscriptions);
      })
      .catch(e => console.log(e));
  }, [currentUser]);

  const setMessage = message => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return '';
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);

    if (userPrivateData && subscriptions) {
      try {
        const resp = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: name,
            },
          },
        });

        const error = resp.error;
        if (error) {
          setMessage(error.message);
          setIsLoading(false);
          return;
        }

        const paymentIntent = resp.paymentIntent;
        setPaymentIntent(paymentIntent);

        if (paymentIntent?.status === 'succeeded') {
          await updateSubscriptionStartDateMaybe(subscriptionId, priceId);

          try {
            const userWithStripeInfoResponse = await sdk.currentUser
              .show({
                include: ['stripeAccount', 'stripeCustomer'],
              })

            const stripeCustomerId = userWithStripeInfoResponse.data.data.attributes.profile.privateData.stripeCustomerId || null;
            if (stripeCustomerId) {
              const { subscriptions } = await post('/api/subscriptions-list', { stripeCustomerId });
              const fetchedSubscriptions = subscriptions.data.map(s => {
                return getPurchasedSubscriptionDetails(s);
              })

              // Extract the most recent subscription (assuming subscriptions have a date or endDate field)
              const lastSubscription = fetchedSubscriptions.sort((a, b) => {
                return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
              })[0];

              await onUpdateUserSubscriptionProfile({
                privateData: {
                  subscriptions: [
                    lastSubscription
                    // ...fetchedSubscriptions,
                  ],
                }
              });
              setUpdatedPrivateData(true);
            }
          } catch (e) {
            console.error('Error updating profile:', e);
          }
        }
      } catch (e) {
        console.error('Error confirming card payment:', e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (paymentIntent && paymentIntent.status === 'succeeded' && updatedPrivateData) {
    return <Redirect to={{ pathname: '/welcome' }} />;
  }

  return (
    <Page
      title={title}
      scrollingDisabled={scrollingDisabled}
    >

      <LayoutSideNavigation
        sideNavClassName={css.navigation}
        topbar={
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="StripeAccountSubscriptionsPage"
          />
        }
        sideNav={
          <>
            <H2 as="h1" className={css.title}>
              Subscription
            </H2>
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <h3>To Join HouseSit Match</h3>
          <br />
          <form onSubmit={handleSubmit}>

            <h4>
              Please complete the registration form and add your payment card details to subscribe to HouseSit Match
            </h4>
            <div className={css.formControl}>
              <label htmlFor="name">Full name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div style={{ width: '100%', height: '20px' }}></div>

            <label>Payment card details</label>
            <div className={css.cardElementWrapper}>
              <div className='row g10 space'>
                <label>Card Number</label>
                <label>Expiry, CVC and Zip</label>
              </div>
              <CardElement />
            </div>
            <div style={{ width: '100%', height: '20px' }}></div>
            <Button disabled={isLoading}>Subscribe</Button>
            <div>{messages}</div>
          </form>
        </div>

        <ShowIsBusy isBusy={isLoading} />
      </LayoutSideNavigation >
    </Page >
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onUpdateUserSubscriptionProfile: actionPayload => dispatch(updateUserSubscriptionProfile(actionPayload)),
});

const Subscribe = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(SubscribeComponent);

export default Subscribe;