import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { ARRAY_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { nonEmptyArray, composeValidators, required } from '../../../../util/validators';
import { isUploadImageOverLimitError } from '../../../../util/errors';

import arrow from './arrow.png';

// Import shared components
import { Button, Form, AspectRatioWrapper, FieldSingleSelect, FieldTextInput, FieldSelectCustom, FieldCheckboxGroup, H3 } from '../../../../components';

// Import modules from this directory
import ListingImage from './ListingImage';
import css from './EditListingPhotosForm.module.css';
import { getListingPhotosLimit } from '../../../../util/subscriptionsHelpers';

const ACCEPT_IMAGES = 'image/*';
const SIZE_LIMIT = 20;

const ImageUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadImageError ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

// NOTE: PublishListingError and ShowListingsError are here since Photos panel is the last visible panel
// before creating a new listing. If that order is changed, these should be changed too.
// Create and show listing errors are shown above submit button
const PublishListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
    </p>
  ) : null;
};

const ShowListingsError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
    </p>
  ) : null;
};

// Field component that uses file-input to allow user to select images.
export const FieldAddImage = props => {
  const {
    formApi,
    onImageUploadHandler,
    aspectWidth = 1,
    aspectHeight = 1,
    intl,
    ...rest } = props;

  const [errorContent, setErrorContent] = useState();

  return (
    <Field form={null} {...rest}>
      {fieldprops => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;

        const onChange = e => {
          const file = e.target.files[0];

          if (!file || !file.type.startsWith("image/")) {
            setErrorContent(
              <span style={{ width: '70%', textAlign: 'center' }}>
                {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileType" })}
              </span>
            );
            return;
          }

          if (file.size > SIZE_LIMIT * 1024 * 1024) {
            setErrorContent(
              <span style={{ width: '70%', textAlign: 'center' }}>
                {intl.formatMessage({ id: "FieldAzureImageUpload.invalidFileSize" })}
              </span>
            );
            return;
          }

          formApi.change(`addImage`, file);
          formApi.blur(`addImage`);
          onImageUploadHandler(file);
          setErrorContent();
        };

        const inputProps = { accept, id: name, name, onChange, type };
        return (
          <div className={css.addImageWrapper}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
              <label htmlFor={name} className={classNames(css.addImage, { [css.error]: errorContent })}>
                {errorContent || label}
              </label>
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

// Component that shows listing images from "images" field array
const FieldListingImage = props => {
  const { name, intl, onRemoveImage, aspectWidth, aspectHeight, variantPrefix } = props;
  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const image = input.value;
        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={() => onRemoveImage(image?.id)}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            variantPrefix={variantPrefix}
          />
        ) : null;
      }}
    </Field>
  );
};


export const EditListingPhotosFormComponent = props => {
  const [state, setState] = useState({ imageUploadRequested: false });
  const [submittedImages, setSubmittedImages] = useState([]);

  const [isSitterfavouritesOpen, setIsSitterfavouritesOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState(css.fadeOut);

  useEffect(() => {
    if (isSitterfavouritesOpen) {
      setAnimationClass(css.fadeIn);
    } else {
      setAnimationClass(css.fadeOut);
    }
  }, [isSitterfavouritesOpen]);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          form,
          formId,
          className,
          fetchErrors,
          handleSubmit,
          intl,
          invalid,
          onRemoveImage,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          touched,
          errors,
          values,
          initialValues,
          listingImageConfig,
          listingFieldsConfig,
          currentUser,
          setFormValues,
        } = formRenderProps;

        const onImageUploadHandler = file => {
          setFormValues(values);
          const { listingImageConfig, onImageUpload } = props;
          if (file) {
            setState({ imageUploadRequested: true });

            onImageUpload({ id: `${file.name}_${Date.now()}`, file }, listingImageConfig)
              .then(() => {
                setState({ imageUploadRequested: false });
              })
              .catch(() => {
                setState({ imageUploadRequested: false });
              });
          }
        };

        const images = values.images;
        const { aspectWidth = 1, aspectHeight = 1, variantPrefix } = listingImageConfig;

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const submittedOnce = submittedImages.length > 0;
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid || disabled || submitInProgress || state.imageUploadRequested || ready;
        const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];

        const classes = classNames(css.root, className);

        const propertyTypeOptions = listingFieldsConfig?.find(c => c.key === 'propertyType')?.enumOptions;
        const propertyTypeLabel = listingFieldsConfig?.find(c => c.key === 'propertyType').showConfig?.label;

        const restrictedMobilityOptions = listingFieldsConfig?.find(c => c.key === 'restrictedMobility')?.enumOptions;
        const restrictedMobilityLabel = listingFieldsConfig?.find(c => c.key === 'restrictedMobility').showConfig?.label;

        const sittersOwnPetsOptions = listingFieldsConfig?.find(c => c.key === 'sittersOwnPets')?.enumOptions;
        const sittersOwnPetsLabel = listingFieldsConfig?.find(c => c.key === 'sittersOwnPets').showConfig?.label;

        const sittersWorkFromYourHomeOptions = listingFieldsConfig?.find(c => c.key === 'sittersWorkFromYourHome')?.enumOptions;
        const sittersWorkFromYourHomeLabel = listingFieldsConfig?.find(c => c.key === 'sittersWorkFromYourHome').showConfig?.label;

        const sitterFavouritesOptions = listingFieldsConfig.find(c => c.key === 'sitterFavourites').enumOptions;
        const sitterFavouritesLabel = listingFieldsConfig.find(c => c.key === 'sitterFavourites').showConfig?.label;

        const TITLE_MAX_LENGTH = 60;
        const maxPhotosLimit = getListingPhotosLimit(currentUser);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              handleSubmit(e);
            }}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.updateFailed" />
              </p>
            ) : null}

            <FieldTextInput
              id={`${formId}title`}
              name="title"
              className={css.fieldClass}
              type="text"
              label={intl.formatMessage({ id: 'EditListingPhotosForm.title' })}
              placeholder={intl.formatMessage({ id: 'EditListingPhotosForm.titlePlaceholder' })}
              maxLength={TITLE_MAX_LENGTH}
              validate={required(intl.formatMessage({
                id: 'EditListingPhotosForm.titleRequired',
              }))}
            />

            <FieldSingleSelect
              className={css.fieldClass}
              name="propertyType"
              id={`${formId}propertyType`}
              label={propertyTypeLabel}
              options={propertyTypeOptions}
            />

            <FieldTextInput
              id={`${formId}propertyDescription`}
              name="propertyDescription"
              className={css.fieldClass}
              type="textarea"
              label={intl.formatMessage({ id: 'EditListingPhotosForm.propertyDescription' })}
              placeholder={intl.formatMessage({ id: 'EditListingPhotosForm.propertyDescriptionPlaceholder' })}
            />

            <div className={css.imagesFieldArray}>
              <FieldArray
                name="images"
                validate={composeValidators(
                  nonEmptyArray(
                    intl.formatMessage({
                      id: 'EditListingPhotosForm.imageRequired',
                    })
                  )
                )}
              >
                {({ fields }) =>
                  fields.map((name, index) => (
                    <FieldListingImage
                      key={name}
                      name={name}
                      onRemoveImage={imageId => {
                        fields.remove(index);
                        onRemoveImage(imageId);
                      }}
                      intl={intl}
                      aspectWidth={aspectWidth}
                      aspectHeight={aspectHeight}
                      variantPrefix={variantPrefix}
                    />
                  ))
                }
              </FieldArray>

              {values.images.length < maxPhotosLimit &&
                <FieldAddImage
                  id="addImage"
                  name="addImage"
                  accept={ACCEPT_IMAGES}
                  label={
                    <span className={css.chooseImageText}>
                      <span className={css.chooseImage}>
                        <FormattedMessage id="EditListingPhotosForm.chooseImage" />
                      </span>
                      <span className={css.imageTypes}>
                        <FormattedMessage id="EditListingPhotosForm.imageTypes" />
                      </span>
                    </span>
                  }
                  type="file"
                  disabled={state.imageUploadRequested}
                  formApi={form}
                  onImageUploadHandler={onImageUploadHandler}
                  aspectWidth={aspectWidth}
                  aspectHeight={aspectHeight}
                  intl={intl}
                />
              }
            </div>


            <p className={css.photoTip}>
              <strong>
                <FormattedMessage id="MaxPhotosLimitHouse" values={{ value: maxPhotosLimit }} />
              </strong>
            </p>

            {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}

            <ImageUploadError
              uploadOverLimit={uploadOverLimit}
              uploadImageError={uploadImageError}
            />

            {/* <p className={css.tip}>
              <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
            </p> */}

            <H3 as="h1">
              <FormattedMessage id="EditListingPhotosForm.amenitites" />
            </H3>

            <div
              className={css.dropdown}
              onMouseEnter={() => setIsSitterfavouritesOpen(true)}
              onMouseLeave={() => setIsSitterfavouritesOpen(false)}
            >
              <div
                className={css.dropdownButton}
                onMouseDown={() => setIsSitterfavouritesOpen(!isSitterfavouritesOpen)}
              >
                <label>
                  {sitterFavouritesLabel}
                </label>
                <img src={arrow} alt="Arrow" className={!isSitterfavouritesOpen ? css.arrow : css.arrowRotated} />
              </div>
              <div className={classNames(animationClass, css.dropdownContent)}>
                <FieldCheckboxGroup
                  name="sitterFavourites"
                  id={`${formId}.sitterFavourites`}
                  options={sitterFavouritesOptions}
                />
              </div>
            </div>

            {/* <FieldSelectCustom
              name="sitterFavourites"
              id={`${formId}.sitterFavourites`}
              label={sitterFavouritesLabel}
              options={sitterFavouritesOptions}
              icon={arrow}
            /> */}

            <FieldSingleSelect
              className={css.fieldClass}
              name="restrictedMobility"
              id={`${formId}restrictedMobility`}
              label={restrictedMobilityLabel}
              options={restrictedMobilityOptions}
            />

            <FieldSingleSelect
              className={css.fieldClass}
              name="sittersOwnPets"
              id={`${formId}sittersOwnPets`}
              label={sittersOwnPetsLabel}
              options={sittersOwnPetsOptions}
            />

            <FieldSingleSelect
              className={css.fieldClass}
              name="sittersWorkFromYourHome"
              id={`${formId}sittersWorkFromYourHome`}
              label={sittersWorkFromYourHomeLabel}
              options={sittersWorkFromYourHomeOptions}
            />

            <PublishListingError error={publishListingError} />
            <ShowListingsError error={showListingsError} />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
