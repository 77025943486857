import React, { memo } from 'react';
import { Field } from 'react-final-form';
import css from './FieldListingSelect.module.css';
import { ListingCard, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import ListingSimpleCard from '../ListingCard/ListingSimpleCard';
import ContinueIcon from '../../containers/TopbarContainer/Topbar/images/continue-icon.svg';

const FieldListingSelect = props => {
    const { id, name, label, listings, validate, addHouseButton } = props;

    const FieldListingSelectComponent = ({ input: { onChange, value }, ...rest }) => {
        return (
            <div className={css.root}>
                <label className={css.fieldLabel}>
                    {label}
                </label>

                <div className={css.chipsContainer} >
                    {listings && listings.map((listing, index) => {
                        return (
                            <div
                                className={value === listing.id.uuid ? css.chipSelected : css.chip}
                                key={listing.id.uuid}
                                onClick={e => {
                                    onChange(listing.id.uuid);
                                }}
                            >
                                <ListingSimpleCard
                                    className={css.listing}
                                    listing={listing}
                                    key={listing.id.uuid}
                                    showAuthorInfo={false}
                                    disableLink={true}
                                />
                            </div>
                        );
                    })}

                    {addHouseButton &&
                        <NamedLink name="NewHouseListingPage" className={css.ctaButton}>
                            <FormattedMessage id="ManageListingsPage.addNewHouseListing" />
                            <img src={ContinueIcon} className={css.icon} />
                        </NamedLink>
                    }
                </div>
            </div>
        );
    };

    return (
        <Field id={id} name={name} component={FieldListingSelectComponent} validate={validate} />
    );
};

export default memo(FieldListingSelect);
