import React, { useState } from 'react';
import css from './FieldNumberInput.module.css';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import add from './img/add.svg';
import subtract from './img/subtract.svg';
import ValidationError from '../ValidationError/ValidationError';

const FieldNumberInputComponent = props => {
    const {
        rootClassName,
        className,
        handleAddPet,
        handleRemovePet,
        initialCounter,
        label,
        min,
        step,
        meta,
    } = props;

    const classes = classNames(rootClassName || css.root, className);
    const [value, setValue] = useState(initialCounter || 0);

    const increment = () => {
        setValue((prevValue) => Math.max(Number(min), prevValue + Number(step)));
        if (handleAddPet) {
            handleAddPet();
        }
    };
    const decrement = () => {
        setValue((prevValue) => Math.max(Number(min), prevValue - Number(step)));
        if (handleRemovePet) {
            handleRemovePet();
        }
    };

    return (
        <div className={classes}>
            {label && <span>{label}</span>}
            <div className={css.numberInput}>
                <div type="button" className={css.button} onClick={decrement}>
                    <img src={subtract} alt="Subtract" />
                </div>
                <div className={css.display}>{value}</div>
                <div type="button" className={css.button} onClick={increment}>
                    <img src={add} alt="Add" />
                </div>
            </div>
            <ValidationError fieldMeta={{ ...meta }} />
        </div>
    );
};

const FieldNumberInput = props => {
    return <Field component={FieldNumberInputComponent} {...props} />;
};

export default FieldNumberInput;
