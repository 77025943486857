import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import configs and util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';

// Import shared components
import {
  Form,
  Button,
  FieldNumberInput,
  H4,
} from '../../../../components';

// Import modules from this directory
import css from './EditListingPetsForm.module.css';
import { pets, petsType } from '../../../../util/pets';
import DogFieldArray from './PetFieldArray/DogFieldArray';
import CatFieldArray from './PetFieldArray/CatFieldArray';
import ReptileFieldArray from './PetFieldArray/ReptileFieldArray';
import HorseFieldArray from './PetFieldArray/HorseFieldArray';
import BirdFieldArray from './PetFieldArray/BirdFieldArray';
import SmallPetFieldArray from './PetFieldArray/SmallPetFieldArray';
import FishFieldArray from './PetFieldArray/FishFieldArray';
import PoultryFieldArray from './PetFieldArray/PoultryFieldArray';
import LivestockFieldArray from './PetFieldArray/LivestockFieldArray';

export const EditListingPetsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        formId,
        form: {
          mutators: { push, pop, remove }
        },
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      console.log({ values })

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPetsForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPetsForm.showListingFailed" />
            </p>
          ) : null}

          <H4>
            <FormattedMessage id="EditListingPetsForm.addYourPets" />
          </H4>

          <div className={css.petsContainer}>
            {pets.map((pet, index) =>
              <FieldNumberInput
                key={index}
                className={css.petData}
                id={`${formId}${pet.option}`}
                name={`${pet.option}`}
                label={`${pet.label}`}
                handleAddPet={() => {
                  push(`${pet.option}List`, { images: [''] });
                }}
                handleRemovePet={() => {
                  pop(`${pet.option}List`);
                }}
                min={0}
                step={1}
                initialCounter={values[`${pet.option}List`]?.length}
              />
            )}
          </div>

          {values?.dogsList?.length > 0 &&
            <DogFieldArray
              intl={intl}
              push={push}
              remove={remove}
              type={petsType.dogs}
            />
          }

          {values?.catsList?.length > 0 &&
            <CatFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }


          {values?.reptilesList?.length > 0 &&
            <ReptileFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.horsesList?.length > 0 &&
            <HorseFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.birdsList?.length > 0 &&
            <BirdFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.smallPetsList?.length > 0 &&
            <SmallPetFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.fishesList?.length > 0 &&
            <FishFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.poultryList?.length > 0 &&
            <PoultryFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          {values?.livestockList?.length > 0 &&
            <LivestockFieldArray
              intl={intl}
              push={push}
              remove={remove}
            />
          }

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPetsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'EditListingPetsForm',
};

EditListingPetsFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPetsFormComponent);