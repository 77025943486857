export const petsType = {
    // allPets: "ALL PETS",
    dogs: "dogs",
    smallPets: "smallPets",
    cats: "cats",
    fishes: "fishes",
    reptiles: "reptiles",
    poultry: "poultry",
    horses: "horses",
    livestock: "livestock",
    birds: "birds",
}

export const pets = [
    // { label: "ALL PETS", option: petsType.allPets },
    { label: "Dogs", option: petsType.dogs },
    { label: "Small pets", option: petsType.smallPets },
    { label: "Cats", option: petsType.cats },
    { label: "Fish", option: petsType.fishes },
    { label: "Reptiles", option: petsType.reptiles },
    { label: "Poultry", option: petsType.poultry },
    { label: "Horses", option: petsType.horses },
    { label: "Livestock", option: petsType.livestock },
    { label: "Birds", option: petsType.birds },
]
