import React, { useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { H3, ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingPhotosForm from './EditListingPhotosForm';
import css from './EditListingPhotosPanel.module.css';

const getInitialValues = (props, formValues) => {

  const { images } = props;
  const { publicData, title } = props?.listing?.attributes || {};

  const {
    propertyType,
    propertyDescription,
    sitterFavourites,
    restrictedMobility,
    sittersOwnPets,
    sittersWorkFromYourHome,
  } = publicData || {};

  const rehydrateSitterFavourites = sitterFavourites?.map(value => (value !== null ? [value] : null));

  return {
    images,
    propertyType: formValues ? formValues.propertyType : propertyType,
    propertyDescription: formValues ? formValues.propertyDescription : propertyDescription,
    sitterFavourites: formValues ? formValues.sitterFavourites : rehydrateSitterFavourites,
    restrictedMobility: formValues ? formValues.restrictedMobility : restrictedMobility,
    sittersOwnPets: formValues ? formValues.sittersOwnPets : sittersOwnPets,
    sittersWorkFromYourHome: formValues ? formValues.sittersWorkFromYourHome : sittersWorkFromYourHome,
    title: formValues ? formValues.title : title,
  };
};

const EditListingPhotosPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    onImageUpload,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSubmit,
    onRemoveImage,
    listingImageConfig,
    listingFieldsConfig,
    currentUser,
  } = props;

  const [formValues, setFormValues] = useState();

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <H3 as="h1">
        {/* {isPublished ? (
          <FormattedMessage
            id="EditListingPhotosPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingPhotosPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
          )} */}
        <FormattedMessage id="EditListingPhotosPanel.createListingTitleCustom" />
      </H3>
      <EditListingPhotosForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={getInitialValues(props, formValues)}
        onImageUpload={onImageUpload}
        setFormValues={setFormValues}
        onSubmit={values => {
          const {
            addImage,
            images,
            title,
            propertyType,
            propertyDescription,
            sitterFavourites,
            restrictedMobility,
            sittersOwnPets,
            sittersWorkFromYourHome,
          } = values;

          const flatSitterFavourites = sitterFavourites?.map(inner => Array.isArray(inner) ? inner[0] || null : null);

          const updatedValues = {
            title,
            images: [...images],
            publicData: {
              propertyType,
              propertyDescription,
              sitterFavourites: flatSitterFavourites,
              restrictedMobility,
              sittersOwnPets,
              sittersWorkFromYourHome,
            }
          }

          onSubmit(updatedValues);
        }}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        listingImageConfig={listingImageConfig}
        listingFieldsConfig={listingFieldsConfig}
        currentUser={currentUser}
      />
    </div>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default EditListingPhotosPanel;
