import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import configs and util modules
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  requiredFieldArrayCheckbox,
  required,
} from '../../../../util/validators';

// Import shared components
import {
  Form,
  FieldLocationAutocompleteInput,
  Button,
  FieldTextInput,
  FieldMultipleItems,
  FieldSingleSelect,
} from '../../../../components';

// Import modules from this directory
import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        listingFieldsConfig,
        values,
        form
      } = formRenderProps;

      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const locationCharacteristicsOptions = listingFieldsConfig?.find(c => c.key === 'locationCharacteristics')?.enumOptions;
      const locationCharacteristicsLabel = intl.formatMessage({ id: 'EditListingLocationForm.locationCharacteristicsLabel' })
      const locationCharacteristicsRequired = listingFieldsConfig?.find(c => c.key === 'locationCharacteristics').saveConfig.requiredMessage;

      const publicTransportAccessOptions = listingFieldsConfig?.find(c => c.key === 'publicTransportAccess')?.enumOptions;
      const publicTransportAccessLabel = listingFieldsConfig?.find(c => c.key === 'publicTransportAccess').showConfig?.label;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.updateFailed" />
            </p>
          ) : null}

          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.showListingFailed" />
            </p>
          ) : null}

          <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus={autoFocus}
            name="location"
            label={intl.formatMessage({ id: 'EditListingLocationForm.address' })}
            placeholder={intl.formatMessage({
              id: 'EditListingLocationForm.addressPlaceholder',
            })}
            onPickLocation={(pickedOption) => {
              form.change("city", pickedOption.selectedPlace?.city || "")
              form.change("country", pickedOption.selectedPlace?.country || "")
            }}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <div className='row gap30 mb40'>

            <FieldTextInput
              className='halfWidth'
              type="text"
              name="city"
              id={`${formId}city`}
              label={intl.formatMessage({ id: 'EditListingLocationForm.city' }, { optionalText })}
              placeholder={intl.formatMessage({
                id: 'EditListingLocationForm.cityPlaceholder',
              })}
              validated={required(intl.formatMessage({ id: 'EditListingLocationForm.cityRequired', }))}
            />


            <FieldTextInput
              className='halfWidth'
              type="text"
              name="country"
              id={`${formId}country`}
              label={intl.formatMessage({ id: 'EditListingLocationForm.country' }, { optionalText })}
              placeholder={intl.formatMessage({
                id: 'EditListingLocationForm.countryPlaceholder',
              })}
              validated={required(intl.formatMessage({ id: 'EditListingLocationForm.countryRequired', }))}
            />
          </div>

          <FieldMultipleItems
            className={css.fieldClass}
            name="locationCharacteristics"
            id={`${formId}locationCharacteristics`}
            label={locationCharacteristicsLabel}
            options={locationCharacteristicsOptions}
          // validate={requiredFieldArrayCheckbox(locationCharacteristicsRequired)}
          />

          <FieldSingleSelect
            className={css.fieldClass}
            name="publicTransportAccess"
            id={`${formId}publicTransportAccess`}
            label={publicTransportAccessLabel}
            options={publicTransportAccessOptions}
          />

          {/* <FieldTextInput
            className={css.building}
            type="text"
            name="building"
            id={`${formId}building`}
            label={intl.formatMessage({ id: 'EditListingLocationForm.building' }, { optionalText })}
            placeholder={intl.formatMessage({
              id: 'EditListingLocationForm.buildingPlaceholder',
            })}
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'EditListingLocationForm',
};

EditListingLocationFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
