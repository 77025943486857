import moment from 'moment';
import { post } from './api';
import { getUserType, userTypes } from './userTypes';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const subscriptionTypes = {
    premium: 'premium',
    choice: 'choice',
    value: 'value'
}

export const UIControls = {
    verifyButton: "verifyButton",
    addListingButton: "addListingButton",
    addHouseSitterProfileButton: "addHouseSitterProfileButton",
    findAHouseSitButton: "findAHouseSitButton",
    verifilePoliceCheckingButton: "verifilePoliceCheckingButton",
}


export const getUserSubscription = (currentUser) => {
    if (!currentUser) {
        return null;
    }

    const activeSubscriptions = currentUser.attributes.profile.privateData.subscriptions ?
        currentUser.attributes.profile.privateData.subscriptions
            .filter(
                (s) =>
                    s && s.status === "active" &&
                    moment(s.endDate).isAfter(moment())
            )
            .sort((a, b) => moment(a.endDate).diff(moment(b.endDate))) : null;

    return activeSubscriptions?.length > 0 ? activeSubscriptions[0].subscriptionType : null;
}


export const getSubscriptionDetails = (priceId, subscriptionPrices) => {
    const subscriptionForPriceId = subscriptionPrices?.find(s => s.id === priceId);
    const lookup = subscriptionForPriceId.lookup_key.split("_");

    return { priceId: priceId, userType: lookup[0], subscriptionType: lookup[1] }
}

export const getPurchasedSubscriptionDetails = (subscription) => {
    const lookup = subscription.plan.nickname.split("_");

    return {
        priceId: subscription.plan.id,
        userType: lookup[0],
        subscriptionType: lookup[1],
        status: subscription.status,
        endDate: moment.unix(subscription.current_period_end).utc().toISOString()
    }
}

export const getSubscriptionPermissions = (userType, subscriptionType, uiOption) => {
    if ((subscriptionType === subscriptionTypes.choice ||
        subscriptionType === subscriptionTypes.premium ||
        subscriptionType === subscriptionTypes.value) &&
        uiOption === UIControls.verifyButton

    ) {
        return true;
    }

    if ((subscriptionType === subscriptionTypes.choice ||
        subscriptionType === subscriptionTypes.premium ||
        subscriptionType === subscriptionTypes.value) &&
        (userType === userTypes.homeowner || userType === userTypes.homeownerAndHousesitter) &&
        uiOption === UIControls.addListingButton
    ) {
        return true;
    }

    if ((subscriptionType === subscriptionTypes.choice ||
        subscriptionType === subscriptionTypes.premium ||
        subscriptionType === subscriptionTypes.value) &&
        (userType === userTypes.housesitter
            || userType === userTypes.homeownerAndHousesitter
        ) &&
        uiOption === UIControls.addHouseSitterProfileButton
    ) {
        return true;
    }

    if ((subscriptionType === subscriptionTypes.choice ||
        subscriptionType === subscriptionTypes.premium ||
        subscriptionType === subscriptionTypes.value) &&
        (userType === userTypes.housesitter
            || userType === userTypes.homeownerAndHousesitter
        ) &&
        uiOption === UIControls.findAHouseSitButton
    ) {
        return true;
    }

    if ((subscriptionType === subscriptionTypes.choice ||
        subscriptionType === subscriptionTypes.premium ||
        subscriptionType === subscriptionTypes.value) &&
        (userType === userTypes.housesitter
            || userType === userTypes.homeownerAndHousesitter
        ) &&
        uiOption === UIControls.verifilePoliceCheckingButton
    ) {
        return true;
    }

    return false;
}

export const getDescriptionWordsLimit = (currentUser) => {
    const userType = getUserType(currentUser);
    const subscriptionType = getUserSubscription(currentUser);
    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.value) {
        return 300;
    }
    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.choice) {
        return 500;
    }

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.premium) {
        return 800;
    }

    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.value) {
        return 300;
    }
    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.choice) {
        return 500;
    }

    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.premium) {
        return 800;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.value) {
        return 300;
    }
    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.choice) {
        return 500;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.premium) {
        return 800;
    }
}

export const getListingPhotosLimit = (currentUser) => {
    const userType = getUserType(currentUser);
    const subscriptionType = getUserSubscription(currentUser);

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.value) {
        return 7;
    }
    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.choice) {
        return 15;
    }

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.premium) {
        return 20;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.value) {
        return 5;
    }
    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.choice) {
        return 10;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.premium) {
        return 20;
    }
}

export const getMaxHousesCount = (currentUser) => {
    const userType = getUserType(currentUser);
    const subscriptionType = getUserSubscription(currentUser);

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.value) {
        return 1;
    }
    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.choice) {
        return 1;
    }

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.premium) {
        return 3;
    }

    return 1;
}

export const getUserPhotosLimit = (currentUser) => {
    const userType = getUserType(currentUser);
    const subscriptionType = getUserSubscription(currentUser);

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.value) {
        return 1;
    }
    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.choice) {
        return 3;
    }

    if (userType === userTypes.homeowner && subscriptionType === subscriptionTypes.premium) {
        return 5;
    }

    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.value) {
        return 1;
    }
    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.choice) {
        return 3;
    }

    if (userType === userTypes.housesitter && subscriptionType === subscriptionTypes.premium) {
        return 5;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.value) {
        return 1;
    }
    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.choice) {
        return 3;
    }

    if (userType === userTypes.homeownerAndHousesitter && subscriptionType === subscriptionTypes.premium) {
        return 5;
    }
}


export const getSubscriptions = (setIsStripeConnected, setSubscriptions) => {
    sdk.currentUser
        .show({
            include: ['stripeAccount', 'stripeCustomer'],
        })
        .then(resp => {
            const isConnected = resp.data.data.attributes.stripeConnected;
            const userEmail = resp.data.data.attributes.email;
            const userId = resp.data.data.id.uuid;
            const hasCustomerId = resp.data.data.attributes.profile.privateData
                ? resp.data.data.attributes.profile.privateData.stripeCustomerId
                    ? resp.data.data.attributes.profile.privateData.stripeCustomerId
                    : null
                : null;
            setIsStripeConnected(isConnected);
            return [hasCustomerId, userEmail, userId];
        })
        .then(resp => {
            const stripeCustomerId = resp[0];
            const userEmail = resp[1];
            const userId = resp[2];

            if (stripeCustomerId) {
                const requestOptions = {
                    stripeCustomerId: stripeCustomerId,
                };

                const fetchData = async () => {
                    const { subscriptions } = await post('/api/subscriptions-list', requestOptions);

                    setSubscriptions(subscriptions.data);
                };
                fetchData();
            } else {
                post('/api/create-customer', {
                    email: userEmail,
                    userId: userId,
                }).catch(error => console.log(error));

                setSubscriptions([]);
            }
        })
        .catch(e => console.log(e));
};

export const getCurrentUserSubscription = (currentUser) => {
    if (!currentUser || !currentUser.attributes || !currentUser.attributes.profile?.privateData) {
        return false;
    }

    const activeSubscriptions = currentUser.attributes.profile.privateData.subscriptions ?
        currentUser.attributes.profile.privateData.subscriptions
            .filter(
                (s) =>
                    s && s.status === "active" &&
                    moment(s.endDate).isAfter(moment())
            ) : null;

    return activeSubscriptions?.length > 0
};