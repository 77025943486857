import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { H3, ListingLink } from '../../../../components';

// Import modules from this directory
import EditListingHouseSitterPhotosForm from './EditListingHouseSitterPhotosForm';
import css from './EditListingHouseSitterPhotosPanel.module.css';

const getInitialValues = params => {
  const { images } = params;
  const { publicData, title } = params?.listing?.attributes || {};

  const {
    propertyType,
    propertyDescription,
    sitterFavourites,
    restrictedMobility,
    sittersOwnPets,
    sittersWorkFromYourHome,
  } = publicData || {};

  return {
    images,
    propertyType,
    propertyDescription,
    sitterFavourites,
    restrictedMobility,
    sittersOwnPets,
    sittersWorkFromYourHome,
    title
  };
};

const EditListingHouseSitterPhotosPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    onImageUpload,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSubmit,
    onRemoveImage,
    listingImageConfig,
    listingFieldsConfig,
    currentUser,
    config,
    onUpdateProfileImage,
    onManageDisableScrolling,
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <H3 as="h1">
        {/* {isPublished ? (
          <FormattedMessage
            id="EditListingHouseSitterPhotosPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingHouseSitterPhotosPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
          )} */}
        <FormattedMessage id="EditListingHouseSitterPhotosPanel.createListingTitleCustom" />
      </H3>
      <EditListingHouseSitterPhotosForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        config={config}
        initialValues={getInitialValues(props)}
        onImageUpload={onImageUpload}
        onSubmit={values => {
          const {
            addImage,
            images,
            title,
            propertyType,
            propertyDescription,
            sitterFavourites,
            restrictedMobility,
            sittersOwnPets,
            sittersWorkFromYourHome,
          } = values;

          const updatedValues = {
            title,
            images: [...images],
            publicData: {
              propertyType,
              propertyDescription,
              sitterFavourites,
              restrictedMobility,
              sittersOwnPets,
              sittersWorkFromYourHome,
            }
          }

          onSubmit(updatedValues);
        }}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        listingImageConfig={listingImageConfig}
        listingFieldsConfig={listingFieldsConfig}
        currentUser={currentUser}
        onUpdateProfileImage={onUpdateProfileImage}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

EditListingHouseSitterPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingHouseSitterPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default EditListingHouseSitterPhotosPanel;
