import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { post } from '../../util/api';
import PropTypes from 'prop-types';


import css from './VerifyButton.module.css';

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    console.log('currentUser start:', this.props.currentUser);


    // TODO big case when came after login this is null??
    if (typeof window !== 'undefined') {
      if (this.props.currentUser === null || this.props.currentUser === undefined) {
        sessionStorage.removeItem('submitted3');
        window.location.href = '/login';
        return;
      }
    }

    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('submitted3', true);
    }

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const response = await post('/api/create-verification-session', {
      id: this.props.currentUser.id.uuid,
    });

    console.log(response);
    const session = response; // await response.json();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error);
    } else {
      console.log('Verification submitted!');
      window.location.href = '/profile-settings';
    }
  }

  render() {
    const { stripe } = this.state;
    return (

      // <a href="#" className={css.menuLink}
      //   onClick={this.handleClick}
      // ><span className={css.menuItemBorder}>
      //     <FormattedMessage id="TopbarDesktop.verify" />
      //     {/* Verify */}
      //   </span></a>

      <a href="#" className={css.menuLink} onClick={this.handleClick}>
        <span className={css.menuItemBorder} />
        <FormattedMessage id="TopbarDesktop.verify" />
      </a>


      // <NamedLink className={css.createNewListingLink} name="NewListingPage">
      //     <FormattedMessage id="TopbarMobileMenu.newListingLink" />
      //   </NamedLink>
    );
  }
}

VerifyButton.propTypes = {
  stripePromise: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default VerifyButton;